import styled from 'styled-components';
import { AiFillPlusCircle } from 'react-icons/ai';
import { openMenuPlus } from '../../features/ui/uiSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ButtonJoinGroup = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const handleClick = () => {
		dispatch(openMenuPlus());
	};

	return (
		<StyledButtonJoinGroup>
			<button className="btn btn-add-group" onClick={handleClick}>
				<AiFillPlusCircle size="1.1em" /> {t('home.menuplus.join_gp')}
			</button>
		</StyledButtonJoinGroup>
	);
};

const StyledButtonJoinGroup = styled.div`
	width: 100%;
	display: flex;

	justify-content: center;
	.btn-add-group {
		color: var(--clr-white);
		background: var(--vooter-green);
		font-size: bold;
		gap: 5px;
		width: 80%;
		height: 45px;
	}
`;

export default ButtonJoinGroup;
