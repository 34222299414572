import axios from 'axios';
import { useEffect, useState } from 'react';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const useAxios = ({ url, method, body = null, headers = null }) => {
	const [response, setResponse] = useState(null);
	const [error, setError] = useState('');
	const [loading, setloading] = useState(true);

	const fetchData = () => {
		axios[method](url, headers, body)
			.then((res) => {
				setResponse(res.data);
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => {
				setloading(false);
			});
	};

	useEffect(() => {
		fetchData();
	}, [method, url, JSON.stringify(body), JSON.stringify(headers)]);

	return { response, error, loading };
};
