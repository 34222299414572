import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { addCurrentCard } from '../../../features/diffusion/diffusionSlice';
import { addGroupByTag, getGroupTag, reset } from '../../../features/group/groupSlice';
import { showNextCard } from '../../../features/ui/uiSlice';

const ChoicesTypeOne = ({ card }) => {
	const dispatch = useDispatch();
	const { isSuccess } = useSelector((state) => state.group);

	useEffect(() => {
		dispatch(addCurrentCard(card));
	}, [card]);

	useEffect(() => {
		if (isSuccess) {
			dispatch(showNextCard());
			dispatch(reset());
		}
	}, [isSuccess]);

	const handleAddGroup = (tag) => {
		dispatch(getGroupTag(tag));
		dispatch(addGroupByTag());
	};
	return (
		<StyledChoices>
			<div className="box ">
				<p className="info">{card.about}</p>
			</div>
			{card.call_to_action_id === null && ''}
			{card.call_to_action_id === '5' && card.call_to_action_data && (
				<div className="call_to_action center-fl" onClick={() => handleAddGroup(card.call_to_action_data)}>
					{card.call_to_action_label}
				</div>
			)}
			{card.call_to_action_id === '2' && card.call_to_action_data && (
				<div className="call_to_action center-fl">
					<a href={card.call_to_action_data} target="_blank">
						{card.call_to_action_label}
					</a>
				</div>
			)}
		</StyledChoices>
	);
};

const StyledChoices = styled.div`
	background: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	width: 100%;
	flex: 1;
	max-height: 78%;
	.box {
		overflow: scroll;
		width: 90%;
		flex: 1;
		text-align: center;
		margin: 5% 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.info {
		font-size: 0.9rem;
	}
	.call_to_action {
		background-color: var(--vooter-orange);
		width: 100%;
		padding: 2%;
		text-align: center;
		height: 15%;
		color: white;
		font-size: 1.2rem;
		a {
			color: white;
		}
		transition: background-color 0.3s ease-in-out;
		&:hover {
			background-color: var(--vooter-orange-dark);
		}
	}
`;

export default ChoicesTypeOne;
