import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AlternativeChoice from './AlternativeChoice';
import { addAnswer, addCurrentCard } from '../../../features/diffusion/diffusionSlice';
import { forwardRef } from 'react/cjs/react.production.min';
import { setMove } from '../../../features/ui/uiSlice';
import arrow from '../../../assets/icon_double_arrow.svg';

//type by organization
const ChoicesTypeFive = forwardRef(({ card }, ref) => {
	const dispatch = useDispatch();
	const [selected, setSelected] = useState([]);
	const [choices, setChoices] = useState(card.card_choice);

	//pour choix alternative
	const handleSelected = (id) => {
		if (selected.find((searchId) => searchId === id)) {
			setSelected(selected.filter((it) => it !== id));
		}
		return;
	};

	const handleAnswer = () => {
		let pts = choices.length;
		let choicesCopy = [...choices];
		let newSelected = [];
		choicesCopy.forEach((ch) => {
			pts -= 1;
			ch = { card_choice_id: ch.id, value: pts };
			newSelected.push(ch);
		});
		setSelected(newSelected);
		dispatch(addAnswer(newSelected));
	};

	function handleOnDragEnd(result) {
		const newChoices = [...choices];
		const [reorderedChoice] = newChoices.splice(result.source.index, 1);
		newChoices.splice(result.destination.index, 0, reorderedChoice);
		setChoices(newChoices);
		dispatch(setMove());
	}

	useEffect(() => {
		dispatch(addCurrentCard(card));
		handleAnswer();
	}, [card, choices]);

	return (
		<>
			<DragDropContext onDragEnd={handleOnDragEnd}>
				<StyledChoices>
					<span>+</span>
					<Droppable droppableId="cards">
						{(provided, snapshot) => (
							<ul className="cards" {...provided.droppableProps} ref={provided.innerRef}>
								{choices.map((choice, index) => {
									return (
										<Draggable key={choice.id} draggableId={choice.id} index={index}>
											{(provided, snapshot) => {
												const style = {
													backgroundColor: snapshot.isDragging ? 'var(--vooter-green)' : '#686868',
													...provided.draggableProps.style,
												};
												return (
													<li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={style}>
														{choice.value}rrr{choice.id}
														<img src={arrow} className="arrow" />
													</li>
												);
											}}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</ul>
						)}
					</Droppable>
					<span>_</span>
				</StyledChoices>
			</DragDropContext>

			{card.alternative_votes == '1' && <AlternativeChoice selected={selected} handleSelected={handleSelected} extra={card.card_choice_extra} />}
		</>
	);
});

const StyledChoices = styled.div`
	background: linear-gradient(0deg, rgba(235, 167, 138, 0.68) 0%, rgba(255, 255, 255, 0.88) 45%, rgba(255, 255, 255, 0.88) 55%, rgba(118, 211, 150, 0.72) 100%);
	display: flex;
	flex: 1;
	align-items: center;
	flex-direction: column;
	justify-content: space-evenly;
	width: 100%;
	padding: 0 4% 4% 4%;
	gap: 2%;
	position: relative;
	ul {
		display: flex;
		height: 100%;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		gap: 3%;
	}
	li[data-rbd-drag-handle-context-id] {
		color: white;
		height: 2.9rem;
		width: 100%;
		display: flex;
		padding: 2% 8%;
		justify-content: center;
		align-items: center;
		text-align: center;
		border-radius: 20px;
		position: relative;
	}
	img.arrow {
		width: 25px;
		height: 25px;
		z-index: 99;
		position: absolute;
		right: 10px;
	}
	span {
		font-size: 1.4rem;
		color: white;
		font-weight: bold;
	}
`;

export default ChoicesTypeFive;
