import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { deleteUserGroup } from '../../features/group/groupSlice';
import { closeModalConfirm } from '../../features/ui/uiSlice';

const ModalConfirm = ({ id, name }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const handleClickConfirm = (id) => {
		dispatch(deleteUserGroup(id));
		dispatch(closeModalConfirm());
		navigate('/m/');
	};
	const handleClickCancel = () => {
		dispatch(closeModalConfirm());
		navigate('/m/groups');
	};
	return (
		<StyleModal
			as={motion.div}
			animate={{ opacity: 1 }}
			initial={{ opacity: 0 }}
			exit={{ opacity: 0 }}
		>
			<p>
				{t('group.modal_confirm.message')} <span>{name}</span> ?
			</p>
			<div className="actions">
				<button className="cancel" onClick={() => handleClickCancel()}>
					{t('global.cancel')}
				</button>
				<button onClick={() => handleClickConfirm(id)}>{t('global.confirm')}</button>
			</div>
		</StyleModal>
	);
};

const StyleModal = styled.div`
	width: 75%;
	height: 200px;
	z-index: 55;
	position: fixed;
	top: 10%;
	border-radius: 1.5rem;
	background-color: #fff;
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	p {
		text-align: center;
		font-size: 1rem;
		padding: 1rem;
	}
	span {
		font-weight: bold;
	}
	.actions {
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 60%;
		gap: 1rem;
		button {
			background: var(--vooter-pink);
			color: white;
			padding: 0.4rem 1rem;
			border-radius: 5px;
			border: none;
			&.cancel {
				background: white;
				color: var(--vooter-pink);
				border: 1px solid var(--vooter-pink);
			}
		}
	}
`;

export default ModalConfirm;
