import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Loader from '../UI/Loader';
import { addGroupById, findGroupByGeoloc, findPublicGroup, resetIsSuccess } from '../../features/group/groupSlice';
import { closeMenuPlus } from '../../features/ui/uiSlice';
import { useTranslation } from 'react-i18next';

const MenuPlusGroupList = () => {
	const { t } = useTranslation();
	const { publicGroup, geolocGroup, isLoading } = useSelector((state) => state.group);

	const handleAddGroup = (id) => {
		dispatch(addGroupById(id));
		dispatch(closeMenuPlus());
		dispatch(resetIsSuccess());
	};

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(findGroupByGeoloc());
		dispatch(findPublicGroup());
	}, []);

	return (
		<StyledGroupListGl>
			<div className="group-list">
				<div className="group-list-title center-fl"> {t('home.menuplus.select_gp_public')}</div>
				<div className="group-list-content">
					{geolocGroup?.length > 0 &&
						geolocGroup.map((group) => (
							<div className="group-list-body center-fl" key={group.id} onClick={() => handleAddGroup(group.id)}>
								<div className="group-list-item">
									<img className="group-logo" src={group?.logo_media?.medium_url} alt="" />
									<div className="group-name">{group.name}</div>
									<div className="group-security">{t('home.menuplus.select_gp_loc')}</div>
								</div>
							</div>
						))}{' '}
					{isLoading && <Loader />}
					{!isLoading &&
						publicGroup?.length > 0 &&
						publicGroup.map((group) => (
							<div className="group-list-body center-fl" key={group.id} onClick={() => handleAddGroup(group.id)}>
								<div className="group-list-item">
									<img className="group-logo" src={group?.logo_media?.medium_url} alt="" />
									<div className="group-name">{group.name}</div>
									<div className="group-security">{t('home.menuplus.gp_public')}</div>
								</div>
							</div>
						))}
					{!isLoading && publicGroup?.length === 0 && geolocGroup?.length === 0 && (
						<div className="group-list-body center-fl">
							<p className="noGroup">{t('home.menuplus.no_gp')}</p>
						</div>
					)}
				</div>
			</div>
		</StyledGroupListGl>
	);
};

const StyledGroupListGl = styled.div`
	.group-list {
		background: var(--clr-white);
		border-radius: 10px;
		&-content {
			padding-bottom: 1%;
		}
		&-title {
			background: var(--light-blue4);
			color: var(--clr-white);
			border-radius: 10px 10px 0 0;
			height: 35px;
			text-align: center;
		}
		&-body {
			flex-direction: column;
			margin: 1% 0;
		}
		&-item {
			background: var(--light-blue5);
			height: 45px;
			width: 85%;
			border-radius: 10px;
			display: flex;
			justify-content: start;
			align-items: center;
			position: relative;
			margin: 1% auto;
			cursor: pointer;
			img {
				height: 45px;
				width: 45px;
				border-radius: 10px 0 0 10px;
				border: lightgrey 1px solid;
				margin-right: 5px;
				background-color: white;
			}
			.group-security {
				font-size: 0.65rem;
				color: grey;
				position: absolute;
				right: 5px;
				bottom: 3px;
			}
		}
	}
	.noGroup {
		margin: 2% auto 3%;
		height: 5%;
	}
	.group-name {
		margin-top: -3%;
	}
`;

export default MenuPlusGroupList;
