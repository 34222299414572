import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import logo from '../assets/big_logo.svg';
import lock from '../assets/icon_lock.svg';
import email from '../assets/icon_mail.svg';
import { getUser, login } from '../features/auth/authSlice';

const Login = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { isLogin } = useSelector((state) => state.auth);

	const [inputValue, setInputValue] = useState({ email: '', password: '' });

	useEffect(() => {
		isLogin && navigate('/m/');
		dispatch(getUser());
	}, [isLogin]);

	const handleChangeInput = (e) => {
		e.preventDefault();
		const value = e.target.value;
		setInputValue((prevState) => ({
			...prevState,
			[e.target.name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		inputValue.email == '' && toast.error(t('toast.email_missing'));
		inputValue.password == '' && toast.error(t('toast.password_missing'));
		inputValue.email == '' && inputValue.password == '' && toast.error(t('toast.identity'));
		inputValue.email != '' && inputValue.password != '' && dispatch(login(inputValue));
	};

	return (
		<StyledLogin>
			<div className="logo">
				<img src={logo} alt="logo" />
			</div>
			<div className="login-box">
				<form className="form-login" onSubmit={handleSubmit}>
					<div className="input-wrap">
						<div className="input_email">
							<img className="icon_input" src={email} alt="" />{' '}
							<input
								type="email"
								name="email"
								id="email"
								value={inputValue.email}
								onChange={handleChangeInput}
								placeholder={t('login.email')}
								required
							/>
						</div>
						<div className="input_pw">
							<img className="icon_input" src={lock} alt="" />
							<input
								type="password"
								name="password"
								value={inputValue.password}
								id="password"
								placeholder={t('login.password')}
								onChange={handleChangeInput}
								required
							/>
						</div>
						<SDivPwdLost>
							<a href="/forgotpassword">{t('login.forgotpassword')}</a>
						</SDivPwdLost>
					</div>

					<div className="btn-wrap">
						<button className="btn login" type="submit">
							{t('login.connexion')}
						</button>
						<Link to="/m/signup">
							<button className="btn create-account"> {t('login.create_account')}</button>
						</Link>
					</div>
				</form>
			</div>
			<div className="login-message">
				<p> {t('login.dl_app_call')}</p>
			</div>
		</StyledLogin>
	);
};

const StyledLogin = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	width: 90%;
	margin: 0 auto;
	.wrap {
		flex: 1;
		max-height: 100%;
	}
	.logo {
		object-fit: contain;
		margin-top: 8%;
		text-align: center;
		height: 20%;
		width: 40%;
		img {
			height: 100%;
			width: 100%;
		}
	}
	.login-box {
		flex: 1;
		width: 100%;
		display: flex;
		justify-content: space-between;
		max-width: 500px;
	}
	.form-login {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		min-height: 18.43rem;
		input {
			height: 3.175rem;
			width: 100%;
			border-radius: 10px;
			border: 1px solid #e7e7e7;
			box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
			padding-left: 40px;
		}
		.input_pw,
		.input_email {
			position: relative;
		}
		.icon_input {
			position: absolute;
			top: 15px;
			left: 10px;
		}
		.input-wrap {
			display: flex;
			height: 40%;
			flex-direction: column;
			justify-content: center;
			gap: 5%;
		}
		.btn-wrap {
			display: flex;
			flex-direction: column;
			height: 40%;
			justify-content: center;
			gap: 5%;
			.login {
				background: var(--dark-blue);
				color: white;
			}
			.create-account {
				color: var(--dark-blue);
				width: 100%;
			}
		}
	}

	.login-message {
		margin-bottom: 8%;
		text-align: center;
		max-width: 290px;
		font-size: 12px;
		color: #8b8b8b;
	}
	.btn {
		height: 3.175rem;
		max-width: 100%;
	}
`;

const SDivPwdLost = styled.div`
	text-align: right;
`;

export default Login;
