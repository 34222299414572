import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { addCommentToAnswer } from '../../features/diffusion/diffusionSlice';
import { hideOverlay } from '../../features/ui/uiSlice';

const Comment_modal = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { answers } = useSelector((state) => state.diffusion);
	const { choiceId } = useSelector((state) => state.ui);

	const [comment, setComment] = useState('');

	const handleChange = (e) => {
		setComment(e.target.value);
		e.target.value.length > 0 && setComment(e.target.value);
	};

	const handleComment = (id, comment) => {
		dispatch(addCommentToAnswer({ id, comment }));
		dispatch(hideOverlay());
	};

	useEffect(() => {
		answers.length > 0 &&
			answers.map((answer) => {
				if (answer.card_choice_id == choiceId) {
					setComment(answer.user_comment);
				}
			});
	}, [answers, choiceId]);

	return (
		<StyledModal as={motion.div} animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>
			<textarea name="openQuestion" id="choice_comment" value={comment} placeholder={t('broadcast.choice.write_comment')} maxLength="250" autoFocus onChange={handleChange}></textarea>
			<button className="validate" onClick={() => handleComment(choiceId, comment)}>
				{t('global.validate')}
			</button>
		</StyledModal>
	);
};

const StyledModal = styled.div`
	width: 85%;
	height: 35%;
	min-height: 10rem;
	z-index: 999;
	position: fixed;
	top: 20%;
	justify-self: center;
	border-radius: 1.5rem;
	background-color: white;
	display: flex;
	flex-direction: column;
	textarea {
		resize: none;
		border-radius: 15px;
		border: none;
		padding: 8%;
		width: 100%;
		height: auto;

		flex: 1;
	}
	.validate {
		background-color: var(--vooter-blue);
		height: 20%;
		width: 100%;
		color: white;
		border-radius: 0 0 15px 15px;
		border: none;
		font-size: 1.2rem;
	}
`;

export default Comment_modal;
