import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Diffusions from './layout/Diffusions';
import Home from './layout/Home';
import Login from './layout/Login';
import Message from './layout/Message';
import Signup from './layout/Signup';
// import { AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addGroupByTag, getGroupTag } from './features/group/groupSlice';
import { ExternalGroupAccess } from './layout/ExternalGroupAccess';
import Groups from './layout/Groups';
import { InternalGroupAccess } from './layout/InternalGroupAccess';
import { RefuseInternalAccess } from './layout/RefuseInternalAccess';

import { AppStore } from './services/store/AppStore';

const App = () => {
	const { isLogin } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const query = new URLSearchParams(location.search);

	// Get group tag parameter to force user to join it after login or registration.
	const groupTag = AppStore.useState((s) => s.groupTag);
	if (query.get('gt') && query.get('gt') !== groupTag) {
		AppStore.update((s) => {
			s.groupTag = query.get('gt');
		});
	}

	useEffect(() => {
		if (isLogin && groupTag) {
			dispatch(getGroupTag(groupTag));
			dispatch(addGroupByTag());

			AppStore.update((s) => {
				s.groupTag = null;
				navigate('/m/');
			});
		}
	}, [isLogin, groupTag]);

	return (
		// <AnimatePresence exitBeforeEnter>
		<>
			<Routes key={location.pathname} location={location}>
				<Route path="/m" element={<Home />} />
				<Route path="/m/login" element={<Login />} />
				<Route path="/m/signup" element={<Signup />} />
				<Route path="/m/diffusion/:id" element={<Diffusions />} />
				<Route path="/m/message" element={<Message />}></Route>
				<Route path="/m/groups" element={<Groups />}></Route>
				<Route path="/m/refuseia/:token" element={<RefuseInternalAccess />} />
				<Route path="/m/iga/:iga_token" element={<InternalGroupAccess />} />
				<Route path="/m/ega/:ega_token" element={<ExternalGroupAccess />} />
			</Routes>
			<ToastContainer position="top-center" />
		</>
		// </AnimatePresence>
	);
};

export default App;
