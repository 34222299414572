import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Card from '../components/diffusions/Card';
import Nav from '../components/diffusions/Nav';
import Message from '../layout/Message';
import Overlay from '../components/UI/Overlay';
import { addConsultationId, getCards } from '../features/diffusion/diffusionSlice';
import { setCardsNumber } from '../features/ui/uiSlice';
import Loader from '../components/UI/Loader';
import { genderYobCards } from '../assets/data/genderYob';
import { motion } from 'framer-motion';

const Diffusions = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const { cards, isLoading } = useSelector((state) => state.diffusion);
	const { cardIndex, isEnd, isOverlayShow } = useSelector((state) => state.ui);

	useEffect(() => {
		dispatch(addConsultationId(id));
		id !== 'gyid' && dispatch(getCards());
		id == 'gyid' ? dispatch(setCardsNumber(2)) : dispatch(setCardsNumber(cards.length));
	}, [id, cards.length, isEnd]);

	return isLoading ? (
		<Loader />
	) : (
		<>
			{!isEnd && (
				<StyledDiffusion as={motion.div} animate={{ opacity: 1, x: 0 }} transition={{ type: 'just' }} initial={{ opacity: 0, x: -400 }} exit={{ opacity: 0 }}>
					{isOverlayShow && <Overlay />}
					<Nav />
					{id !== 'gyid' &&
						cards?.map((card, i) => {
							if (cards.length == 1) {
								return <Card card={cards[0]} key={cards[0].id} />;
							}
							return i === cardIndex && <Card card={card} key={card.id} />;
						})}
					{id === 'gyid' &&
						genderYobCards?.map((card, i) => {
							return i === cardIndex && <Card card={card} key={card.id} />;
						})}
				</StyledDiffusion>
			)}
			{isEnd && <Message type="end_diffusion" />}
		</>
	);
};

const StyledDiffusion = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

export default Diffusions;
