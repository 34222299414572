import styled from 'styled-components';
import arrowReturn from '../../assets/icon_arrow_return.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setValue, showCardFront, showNextCard } from '../../features/ui/uiSlice';
import { postAnswers, resetSelected, formatAnswers, addTimeToAnswer, postTrashCard } from '../../features/diffusion/diffusionSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addGyToUserParams, updateUser } from '../../features/auth/authSlice';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const asking = {
	open: t('broadcast.footer.open'),
	unique: t('broadcast.footer.unique'),
	organization: t('broadcast.footer.organization'),
	multi: t('broadcast.footer.multi'),
};

const FooterVerso = ({ type }) => {
	const { t } = useTranslation();
	const { card_type_id, id: cardId } = useSelector((state) => state.diffusion.card);
	const { consultation_id } = useSelector((state) => state.diffusion);
	const { selected, answers, timestampStart } = useSelector((state) => state.diffusion);

	const dispatch = useDispatch();

	const { isValue, isMove } = useSelector((state) => state.ui);

	const showFrontCard = () => {
		dispatch(showCardFront());
	};
	const showNext = () => {
		if (type != '1') {
			if (selected.length > 0) {
				dispatch(showNextCard());
			} else {
				type == '3' && toast.warning(t('toast.order'));
				type != '3' && toast.warning(t('toast.select'));
				return;
			}
		} else {
			dispatch(postTrashCard());
			dispatch(showNextCard());
		}
	};

	const sendAnswers = () => {
		const millis = Date.now() - timestampStart;
		const sec = Math.floor(millis / 1000);
		dispatch(addTimeToAnswer(sec));
		if (consultation_id == 'gyid') {
			dispatch(addGyToUserParams({ cardId, selected }));
			dispatch(showNextCard());
			dispatch(resetSelected());
			dispatch(setValue(false));
			dispatch(updateUser());
		} else {
			if (card_type_id == '5') {
				//send just answers selected
				let newAnswers = answers.filter((answer) => selected.includes(answer.card_choice_id));
				dispatch(formatAnswers(newAnswers));
				dispatch(postAnswers());
				dispatch(showNextCard());
				dispatch(resetSelected());
				dispatch(setValue(false));
			} else {
				dispatch(postAnswers());
				dispatch(showNextCard());
				dispatch(resetSelected());
				dispatch(setValue(false));
			}
		}
	};

	return type === '1' ? (
		<StyledFooterVerso>
			<motion.div className="return" onClick={showFrontCard} whileTap={{ scale: 0.8 }}>
				<img src={arrowReturn} alt="back" />
			</motion.div>
			<span className="next" onClick={showNext}>
				{t('global.continu')}
			</span>
		</StyledFooterVerso>
	) : type === '3' ? (
		<>
			<StyledFooterVerso isMove={isMove}>
				{!isMove && (
					<>
						<motion.div className="return" onClick={showFrontCard} whileTap={{ scale: 0.8 }}>
							<img src={arrowReturn} alt="back" />
						</motion.div>
						<motion.p className="next" onClick={showNext} whileTap={{ scale: 0.8 }}>
							{asking.organization}
						</motion.p>
					</>
				)}
				{isMove && (
					<>
						<motion.div className="return" onClick={showFrontCard} whileTap={{ scale: 0.8 }}>
							<img src={arrowReturn} alt="back" />
						</motion.div>
						<motion.p className="next confirm" onClick={sendAnswers} whileTap={{ scale: 0.8 }}>
							{t('global.confirm')}
						</motion.p>
					</>
				)}
			</StyledFooterVerso>
		</>
	) : (
		<>
			<StyledFooterVerso isValue={isValue}>
				{!isValue && (
					<>
						<motion.div className="return" onClick={showFrontCard} whileTap={{ scale: 0.8 }}>
							<img src={arrowReturn} alt="back" />
						</motion.div>
						<p className="next" onClick={showNext}>
							{type === '4' ? asking.open : type === '2' ? asking.unique : type === '5' ? asking.multi : asking.unique}
						</p>
					</>
				)}
				{isValue && (
					<>
						<motion.div className="return" onClick={showFrontCard} whileTap={{ scale: 0.8 }}>
							<img src={arrowReturn} alt="back" />
						</motion.div>
						<motion.p className="next confirm" onClick={sendAnswers} whileTap={{ scale: 0.8 }}>
							{t('global.confirm')}
						</motion.p>
					</>
				)}
			</StyledFooterVerso>
		</>
	);
};

const StyledFooterVerso = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${(props) => (props.isValue || props.isMove) && 'var(--dark-blue)'};
	color: ${(props) => (props.isValue || props.isMove) && 'white'};
	transition: 0.3s all;
	position: relative;
	&:hover {
		background-color: ${(props) => (props.isValue || props.isMove) && 'var(--dark-blue2)'};
	}
	p.next {
		font-size: 15px;
		cursor: ${(props) => (props.type == '1' || props.isValue ? 'pointer' : 'default')};
		text-align: center;
		width: 60%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 20%;
		font-size: 1rem;
	}
	p.confirm {
		font-size: 1.2rem;
	}
	.return {
		position: absolute;
		left: 2%;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			height: 80%;
		}
	}
`;

export default FooterVerso;
