import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import logo from '../assets/big_logo.svg';
import lock from '../assets/icon_lock.svg';
import email from '../assets/icon_mail.svg';
import { register } from '../features/auth/authSlice';

const Signup = ({ emailByDefault }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { isSuccess } = useSelector((state) => state.auth);
	const [inputValue, setInputValue] = useState({
		email: emailByDefault,
		password: '',
	});
	const { iga_token } = useParams();
	const { ega_token } = useParams();

	const handleChangeInput = (e) => {
		e.preventDefault();
		const value = e.target.value;
		setInputValue((prevState) => ({
			...prevState,
			[e.target.name]: value,
		}));
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		inputValue.email == '' && toast.error(t('toast.email_missing'));
		inputValue.password == '' && toast.error(t('toast.password_missing'));

		dispatch(register({ ...inputValue, iga_token, ega_token }));
	};

	useEffect(() => {
		isSuccess && navigate('/m/');
	}, [isSuccess]);

	return (
		<StyledSignup>
			<div className="logo">
				<img src={logo} alt="logo" />
			</div>
			<div className="login-box">
				<form className="form-signup" onSubmit={handleSubmit}>
					<div className="input-wrap">
						<div className="input_email">
							<img className="icon_input" src={email} alt="" />{' '}
							<input
								type="email"
								name="email"
								id="email"
								value={inputValue.email}
								placeholder={t('register.email')}
								onChange={handleChangeInput}
							/>
						</div>
						<div className="input_pw">
							<img className="icon_input" src={lock} alt="" />
							<input
								type="password"
								name="password"
								id="password"
								value={inputValue.password}
								placeholder={t('register.password')}
								onChange={handleChangeInput}
							/>
						</div>
					</div>

					<p className="cgu">
						{t('register.cgu')}{' '}
						<a href="https://cgu.vooter.co/cgu_app/cgu_fr.html" target="_blank">
							CGU
						</a>
					</p>

					<div className="btn-wrap">
						<button className="btn validate"> {t('global.validate')}</button>
					</div>
				</form>
			</div>
			<div className="signup-message">
				<p>
					{t('register.already_account')} <Link to="/m/login"> {t('register.connexion')}</Link>
				</p>
			</div>
		</StyledSignup>
	);
};

const StyledSignup = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	width: 90%;
	margin: 0 auto;
	.logo {
		object-fit: contain;
		margin-top: 8%;
		text-align: center;
		height: 20%;
		width: 40%;
		img {
			height: 100%;
			width: 100%;
		}
	}

	.login-box {
		height: 290px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 50px;
		max-width: 500px;
	}
	.form-signup {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		input {
			height: 50px;
			width: 100%;
			border-radius: 10px;
			border: 1px solid #e7e7e7;
			box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
			padding-left: 40px;
		}
		.input_pw,
		.input_email {
			position: relative;
		}
		.icon_input {
			position: absolute;
			top: 15px;
			left: 10px;
		}
		.input-wrap {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}
		.btn-wrap {
			display: flex;
			flex-direction: column;
			gap: 20px;

			.validate {
				background: var(--dark-blue);
				color: white;
				max-width: 100%;
			}
		}
		.cgu {
			color: #8b8b8b;
			font-size: 11px;
			text-align: center;
		}
	}
	.signup-message {
		margin-top: 30%;
		padding-bottom: 2%;
		text-align: center;
		max-width: 290px;
		font-size: 12px;
		color: #8b8b8b;
	}
	a {
		color: var(--dark-blue);
	}
`;

Signup.defaultProps = {
	emailByDefault: '',
};

export default Signup;
