import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { hideOverlay, openModalExtraChoice, showNextCard } from '../../../features/ui/uiSlice';
import plus from '../../../assets/icon_plus_circle_blue.svg';
import { addAnswerAlt, addTimeToAnswer, postAnswers } from '../../../features/diffusion/diffusionSlice';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const AlternativeChoice = ({ extra }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { timestampStart } = useSelector((state) => state.diffusion);
	const isOpenModalExtra = useSelector((state) => state.ui.isOpenModalExtraChoice);
	const handleClickExtraChoice = () => {
		dispatch(openModalExtraChoice());
	};

	const sendAnswer = () => {
		const millis = Date.now() - timestampStart;
		const sec = Math.floor(millis / 1000);
		dispatch(addTimeToAnswer(sec));
		dispatch(postAnswers());
		dispatch(hideOverlay());
		dispatch(showNextCard());
	};

	const handleSelectedExtraChoice = (id) => {
		dispatch(addAnswerAlt(id));
		sendAnswer();
	};

	return (
		<>
			<StyledAlternativeChoice>
				<motion.div className="moreChoices" onClick={handleClickExtraChoice} whileTap={{ scale: 0.8 }}>
					<img src={plus} alt="more choices" />
				</motion.div>
			</StyledAlternativeChoice>

			{isOpenModalExtra && (
				<ModalExtraChoice as={motion.div} animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>
					<h5>{t('broadcast.choice.alt.alt')}</h5>
					{extra.map((choice) => {
						return (
							<div
								className="alternatives-choices"
								key={choice.id}
								value={choice.id}
								onClick={() => {
									handleSelectedExtraChoice(choice.id);
								}}
							>
								{choice.value === 'BIASED' && (
									<div className="alt-choice">
										<h6>{t('broadcast.choice.alt.biased_question_title')}</h6>
										<p>{t('broadcast.choice.alt.biased_question_message')}</p>
									</div>
								)}
								{choice.value === 'NOT CONCERNED' && (
									<div className="alt-choice">
										<h6>{t('broadcast.choice.alt.not_concerned_title')}</h6>
										<p>{t('broadcast.choice.alt.not_concerned_message')}</p>
									</div>
								)}
							</div>
						);
					})}
				</ModalExtraChoice>
			)}
		</>
	);
};

const StyledAlternativeChoice = styled.div`
	.moreChoices {
		position: absolute;
		right: 2%;
		height: 11%;
		z-index: 49;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

const ModalExtraChoice = styled.div`
	background: white;
	position: absolute;
	padding: 4%;
	border-radius: 15px;
	top: 11%;
	width: 92%;
	z-index: 99;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 5%;
	align-items: center;
	h5 {
		opacity: 0.8;
		margin-bottom: 2%;
	}
	.alt-choice {
		&:first-of-type {
			margin-bottom: 4%;
		}
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		cursor: pointer;
		background-color: rgba(143, 173, 250, 0.15);
		padding: 1%;
		border: 3px solid transparent;
		border-radius: 10px;
		h6 {
			margin-bottom: 2%;
			font-weight: 700;
			color: var(--vooter-blue);
		}
		p {
			text-align: center;
		}
		&:hover,
		&:focus {
			border: 3px solid #e2e2ef;
			border-radius: 10px;
		}
	}
`;

export default AlternativeChoice;
