import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { loginThunk, getTokenAppThunk, registerThunk, getUserThunk, updateUserThunk } from './authThunk';

import { getUserFromLocalStorage, getTokenAppFromLocalStorage, addTokenAppToLocalStorage, addUserToLocalStorage, removeTokenAppFromLocalStorage, removeUserFromLocalStorage } from '../../utils/localstorage';

import { t } from 'i18next';

const initialState = {
	user: getUserFromLocalStorage(),
	user_params: '',
	isError: false,
	isSuccess: false,
	isLoading: false,
	message: '',
	isLogin: false,
	tokenApp: getTokenAppFromLocalStorage(),
};

export const register = createAsyncThunk('auth/register', registerThunk);

export const login = createAsyncThunk('auth/login', loginThunk);

export const getUser = createAsyncThunk('auth/getUser', getUserThunk);

export const updateUser = createAsyncThunk('auth/updateUser', updateUserThunk);

export const getTokenApp = createAsyncThunk('auth/getTokenApp', getTokenAppThunk);

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state) => {
			state.isLogin = false;
			state.user = null;
			state.tokenApp = null;
			localStorage.clear();
		},
		setIsLogin: (state) => {
			state.isLogin = true;
		},
		reset: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = false;
			state.message = '';
		},
		addGroupByCode: (state, action) => {
			state.groupeCode = action.payload;
		},
		addGyToUserParams: (state, { payload }) => {
			payload.cardId == 'gyid_2' && (state.user_params.yob = payload.selected);
			payload.cardId == 'gyid_1' && (state.user_params.gender = payload.selected);
			state.user_params.yob != null && state.user_params.gender != null && updateUser();
		},
	},
	extraReducers: {
		[login.fulfilled]: (state, action) => {
			state.isLoading = false;
			// state.user_token = action.payload;
			state.isLogin = true;
			addUserToLocalStorage(action.payload);
		},
		[login.pending]: (state, action) => {
			state.isLoading = true;
		},
		[login.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			toast.error(t('toast.identity'));
		},
		[register.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			if (payload[0].errors) {
				let { errors } = payload[0];
				toast.error(errors[Object.keys(errors)[0]]);
				state.message = errors[Object.keys(errors)[0]];
				state.isSuccess = false;
			} else {
				state.isSuccess = true;
				// state.user_token = payload[1];

				localStorage.setItem('user', JSON.stringify(payload[1]));
				state.isLogin = true;
				toast.success(t('toast.create_account'));
			}
		},
		[register.pending]: (state, action) => {
			state.isLoading = true;
		},
		[register.rejected]: (state, { payload }) => {
			state.isLoading = false;
		},
		[getTokenApp.fulfilled]: (state, { payload }) => {
			const { access_token } = payload;
			state.isLoading = false;
			addTokenAppToLocalStorage(access_token);
		},
		[getTokenApp.pending]: (state, action) => {
			state.isLoading = true;
		},
		[getTokenApp.rejected]: (state, action) => {
			state.isLoading = false;
		},
		[getUser.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.user_params = payload;
		},
		[getUser.pending]: (state) => {
			state.isLoading = true;
		},
		[getUser.rejected]: (state) => {
			state.isLoading = false;
		},
		[updateUser.fulfilled]: (state, { payload }) => {
			console.log('updateUser.fulfilled');
		},
		[updateUser.pending]: (state, action) => {
			console.log('updateUser.pending');
		},
		[updateUser.rejected]: (state, action) => {
			console.log('updateUser.rejected');
		},
	},
});

export const { reset, logout, setIsLogin, addGroupByCode, addGyToUserParams } = authSlice.actions;
export default authSlice.reducer;
