import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import back from '../../assets/icon_arrow_back_grey.svg';
import { showCardFront } from '../../features/ui/uiSlice';

const Nav = () => {
	const logo_url = useSelector((state) => state.diffusion?.currentGroup?.logo_media?.base_url);
	const dispatch = useDispatch();
	const resetCard = () => {
		dispatch(showCardFront());
	};
	return (
		<StyledNavDiffusion>
			<div className="wrap">
				<Link to="/m/" onClick={resetCard}>
					<img src={back} alt="back" />
				</Link>

				<div className="wrap-group-logo">
					<img className="group-logo" src={logo_url} alt="group_logo" />
				</div>
			</div>
		</StyledNavDiffusion>
	);
};

const StyledNavDiffusion = styled.div`
	background: white;
	height: 10%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 2.7rem;
	.wrap {
		width: 90%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
	}

	.wrap-group-logo {
		object-fit: contain;
		max-height: 45px;
		max-width: 45px;

		img {
			height: 100%;
			width: 100%;
		}
	}
	.group-logo {
		border-radius: var(--radius);
		border: 1px solid var(--light-grey);
	}
	a {
		height: 100%;
		object-fit: contain;
		display: flex;
		align-items: center;
		img {
			height: 80%;
			width: 80%;
		}
	}
`;

export default Nav;
