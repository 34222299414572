import styled from 'styled-components';
import ButtonJoinGroup from './ButtonJoinGroup';
import smiley from '../../assets/smiley_no_diffusion.svg';
import { useTranslation } from 'react-i18next';

const NoDiffusion = () => {
	const { t } = useTranslation();
	return (
		<StyledNoDiffusion className="section-center center-fl">
			<div className="section-header section-center center-fl">
				<h5 className="section-title">{t('home.welcome')}</h5>
				<p>{t('home.no_broadcast')}</p>
				<div className="img-box">
					<img src={smiley} alt="" />
				</div>
			</div>
			<ButtonJoinGroup />

			<a href="http://onelink.to/vooter" target="_blank">
				{t('home.dl')}
			</a>
		</StyledNoDiffusion>
	);
};

const StyledNoDiffusion = styled.section`
	flex-direction: column;
	margin-top: 7%;
	height: 100%;
	justify-content: space-between;
	.section-header {
		padding: 15px;
		text-align: center;
		flex-direction: column;
		gap: 4%;
	}
	h5 {
		font-size: 1.4rem;
	}
	p {
		margin-bottom: 5%;
	}
	a {
		color: var(--vooter-blue);
		margin-top: 10%;
		text-align: center;
	}
	.img-box {
		object-fit: contain;
		width: 100%;
		height: 100%;
		max-width: 15rem;
		img {
			height: 90%;
			width: 90%;
		}
	}
`;

export default NoDiffusion;
