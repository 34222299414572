import axios from 'axios';

export const getDiffusionsThunk = async (_, thunkAPI) => {
	const { yob, gender, groups_count } = thunkAPI.getState().auth.user_params;

	try {
		//user token
		const token = JSON.parse(localStorage.getItem('user')).access_token;

		const response = await axios({
			method: 'get',
			url: `${process.env.REACT_APP_API_URL}/feed?access_token=${token}`,
			params: { access_token: token, filter_allvoted: 1 },
			responseType: 'json',
			headers: {
				// Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
		});

		return { diffusions: response.data.items, yob, gender, groups_count };
	} catch (error) {
		return thunkAPI.rejectWithValue(error.response.data.message);
	}
};

export const getConsultationThunk = async (with_group, thunkAPI) => {
	const user_id = JSON.parse(localStorage.getItem('user')).user_id;
	try {
		const response = await axios({
			method: 'get',
			params: { access_token: token, user_id: user_id, width_group: with_group, voteapp: 1 },
			url: `${process.env.REACT_APP_API_URL}/consultation/${consultation_id}`,
			responseType: 'json',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		return response.data.consultation;
	} catch (error) {
		return thunkAPI.rejectWithValue(error.response.data.message);
	}
};

export const getCardsThunk = async (_, thunkAPI) => {
	//user token
	const token = JSON.parse(localStorage.getItem('user')).access_token;
	const consultation_id = await thunkAPI.getState().diffusion.consultation_id;

	try {
		const response = await axios({
			method: 'get',
			params: {
				access_token: token,
				consultation_id: consultation_id,
				cards_user_voted: false,
				cards_user_trashed: false,
				withoutResult: true,
			},
			url: `${process.env.REACT_APP_API_URL}/card/`,
			responseType: 'json',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		return response.data.cards;
	} catch (error) {
		return thunkAPI.rejectWithValue(error.response.data.message);
	}
};

export const postAnswersThunk = async (_, thunkAPI) => {
	//user token
	const token = JSON.parse(localStorage.getItem('user')).access_token;

	const cardId = await thunkAPI.getState().diffusion.card.id;
	const time_to_answer = await thunkAPI.getState().diffusion.time_to_answer;
	const answer = await thunkAPI.getState().diffusion.answers;

	try {
		const res = await axios({
			method: 'post',
			data: { acces_token: token, card_id: cardId, answers: JSON.stringify(answer), time_to_answer: time_to_answer },
			url: `${process.env.REACT_APP_API_URL}/answer/?access_token=${token}`,
			responseType: 'json',
			headers: { 'Content-Type': 'application/json' },
		});

		return res.data;
	} catch (error) {
		if (error.response) {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
		} else if (error.request) {
			console.log(error.request);
		} else {
			console.log('Error', error.message);
		}
		return thunkAPI.rejectWithValue('error');
	}
};

export const postTrashCardThunk = async (_, thunkAPI) => {
	//user token
	const token = JSON.parse(localStorage.getItem('user')).access_token;
	const cardId = await thunkAPI.getState().diffusion.card.id;

	try {
		const res = await axios({
			method: 'post',
			data: { acces_token: token, card_id: cardId },
			url: `${process.env.REACT_APP_API_URL}/card-trash/?access_token=${token}`,
			responseType: 'json',
			headers: { 'Content-Type': 'application/json' },
		});

		return res.data;
	} catch (error) {
		if (error.response) {
			console.log(error.response.data);
		} else if (error.request) {
			console.log(error.request);
		} else {
			console.log('Error', error.message);
		}
		return thunkAPI.rejectWithValue('error');
	}
};
