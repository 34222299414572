import styled from 'styled-components';
import info from '../../assets/icon_info_white.svg';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { openModalDetail } from '../../features/ui/uiSlice';
import { useDispatch } from 'react-redux';

const QuestionTop = ({ question, type, about }) => {
	const showModalDetail = useSelector((state) => state.ui.isOpenModalDetail);
	const dispatch = useDispatch();
	const handleModal = () => {
		dispatch(openModalDetail());
	};
	return (
		<StyledQuestionTop>
			<p title={question}>{question}</p>
			{about != null && (
				<>
					<motion.div className="about" onClick={handleModal} whileTap={{ scale: 0.8 }}>
						<img src={info} alt="détails" />
					</motion.div>

					{showModalDetail && (
						<motion.div className="modal-details" animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>
							<span>{about}</span>
						</motion.div>
					)}
				</>
			)}
		</StyledQuestionTop>
	);
};

const StyledQuestionTop = styled.div`
	background-color: ${(props) => (props.type === 'info' ? 'var(--vooter-pink)}' : '#00000097')};
	min-height: 11%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	padding: 2%;
	position: relative;
	height: 3.4rem;
	p {
		text-align: center;
		display: flex;
		font-size: 0.9rem;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.about {
		position: absolute;
		background-color: var(--vooter-orange);
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 1%;
		right: 1%;
		bottom: -34%;
	}
	.modal-details {
		background: white;
		position: absolute;
		color: var(--dark-grey);
		z-index: 999;
		top: 2%;
		width: 90%;
		border-radius: 15px;
		padding: 20px;
	}
`;

export default QuestionTop;
