import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import no_ano from '../../assets/icon_anonymity.svg';
import dot from '../../assets/icon_menu_dots.svg';
import bubble from '../../assets/white_bubble.svg';
import { addCurrentGroup, isTesting } from '../../features/diffusion/diffusionSlice';
import { openModalDiffusionInfo } from '../../features/ui/uiSlice';
import Test from '../UI/Test';

const DiffusionItem = ({ diffusion, isTest }) => {
	const dispatch = useDispatch();

	const openModalInfo = ({ id, type }) => {
		dispatch(openModalDiffusionInfo({ id, type }));
	};

	const handleGroup = (group) => {
		dispatch(addCurrentGroup(group));
		dispatch(isTesting(isTest));
	};

	return (
		<>
			<StyledDiffusionItem>
				<div className="single-diffusion">
					<Link
						to={`/m/diffusion/${diffusion.id}`}
						onClick={() => handleGroup(diffusion.group)}
					>
						<img className="img-diff" src={diffusion.media?.base_url} alt="diffusion_image" />
					</Link>
					<div className="single-diffusion-top">
						{diffusion.anonymity == '0' && <img src={no_ano} className="ano" alt="" />}
						<p className="diffusion-name">{diffusion.name}</p>
						<img
							className="menuDot"
							src={dot}
							alt="détails diffusion"
							onClick={() => openModalInfo({ id: diffusion.id, type: 'diffusion_info' })}
						/>
						<div className="bubble">
							<img src={bubble} alt="nombres de cartes" />
							<span>{diffusion.card_nb}</span>
						</div>
					</div>
					<div
						className="single-diffusion-bottom"
						onClick={() => openModalInfo({ id: diffusion.id, type: 'group_info' })}
					>
						<p className="diffusion-name">{diffusion.group.name}</p>
						<div className="group-logo">
							<img
								className="group-img"
								src={diffusion.group.logo_media?.base_url}
								alt="group_logo"
							/>
						</div>
					</div>
				</div>
				{isTest && <Test />}
			</StyledDiffusionItem>
		</>
	);
};

const StyledDiffusionItem = styled.article`
	height: 268px;
	border-radius: 20px;
	position: relative;
	width: 100%;
	position: relative;
	.img-diff {
		width: 100%;
		height: 100%;
		border-radius: 20px;
		position: absolute;
		z-index: 0;
	}
	.single-diffusion {
		position: relative;
		z-index: 2;
		height: 100%;
		&-top,
		&-bottom {
			height: 38px;
			width: 100%;
			background: rgba(0, 0, 0, 0.4);
			color: var(--clr-white);
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&-top {
			border-radius: 20px 20px 0 0;
			position: relative;
			z-index: 10;
			.diffusion-name {
				font-size: 1rem;
				line-height: 1.1;
				text-align: center;
				padding: 0 10%;
				width: 100%;
			}
			.menuDot {
				position: absolute;
				right: 0;
				padding: 1rem;
				cursor: pointer;
			}
			.bubble {
				background: var(--vooter-orange);
				position: absolute;
				bottom: -40px;
				left: 0;
				height: 40px;
				width: 40px;
				padding: 0.4rem;
				border-radius: 0 0 100% 0;
				img {
					margin-left: -2px;
				}
				span {
					position: absolute;
					top: 0.5rem;
					left: 6px;
					width: 20px;
					text-align: center;
				}
			}
			.ano {
				position: absolute;
				left: 2%;
			}
		}
		&-bottom {
			border-radius: 0 0 20px 20px;
			display: flex;
			justify-content: space-between;
			padding: 0 10px;
			z-index: 10;
			position: relative;
			bottom: -192px;
			cursor: pointer;
			.group-logo {
				height: 50px;
				width: 50px;
				align-self: flex-end;
				margin-bottom: 0.5rem;
				border-radius: var(--radius);
				background: var(--clr-white);
				border: 0.03rem var(--light-grey) solid;
			}
			.group-img {
				height: 100%;
				width: 100%;
				object-fit: contain;
				border-radius: var(--radius);
			}
		}
	}
`;

export default DiffusionItem;
