import React from 'react';
import styled from 'styled-components';

const Theme = ({ theme }) => {
	return (
		<StyledTheme>
			<p>{theme}</p>
		</StyledTheme>
	);
};
const StyledTheme = styled.div`
	width: 100%;
	background: #00000075;
	color: white;
	height: 3.4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	p {
		font-size: 0.9rem;
	}
`;

export default Theme;
