import { motion } from 'framer-motion';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import menu from '../../assets/icon_menu.svg';
import plus from '../../assets/icon_plus_multiaction.svg';
import logo from '../../assets/small_logo.svg';

import { useDispatch } from 'react-redux';
import { openMenuPlus, openSideMenu } from '../../features/ui/uiSlice';

const Navbar = () => {
	const dispatch = useDispatch();
	const openSideMenuHandler = () => {
		dispatch(openSideMenu());
	};
	const openMenuPlusHandler = () => {
		dispatch(openMenuPlus());
	};
	return (
		<StyledNav>
			<div className="nav-center center-fl">
				<div className="nav-action">
					<motion.button type="button" onClick={openSideMenuHandler} whileTap={{ scale: 0.8 }}>
						{<img src={menu} alt="menu" />}
					</motion.button>
					<Link to="/m/">
						<img className="imgnav" src={logo} alt="Vooter" />
					</Link>
					<div>
						<motion.button
							type="button"
							onClick={openMenuPlusHandler}
							whileTap={{ scale: 0.8 }}
						>
							{<img src={plus} alt="action" />}
						</motion.button>
					</div>
				</div>
			</div>
		</StyledNav>
	);
};

const StyledNav = styled.nav`
	width: 100%;
	height: 50px;
	.nav-center {
		padding-top: 7px;
	}
	.nav-action {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 0 1rem;
		height: 55px;
		background: #ffffffd9;
		position: fixed;
		top: 0;
		z-index: 30;
		button {
			background: transparent;
			border: transparent;
			cursor: pointer;
			svg {
				font-size: 2rem;
			}
		}
	}
	.imgnav {
		height: 45px;
		width: 45px;
	}
`;

export default Navbar;
