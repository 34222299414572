import styled from 'styled-components';

const Test = () => {
	return (
		<StyledTest>
			<span>TEST</span>
		</StyledTest>
	);
};

const StyledTest = styled.div`
	position: absolute;
	top: 20%;
	right: 3%;
	border-radius: 0.15rem;
	z-index: 20;
	color: white;
	padding: 0.2rem 0.7rem;
	background: var(--vooter-pink);
`;

export default Test;
