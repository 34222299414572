import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import {
	addGroupByIdThunk,
	addGroupByTagThunk,
	checkInternalGroupAccessThunk,
	deleteUserGroupThunk,
	findGroupByGeolocThunk,
	findPublicGroupThunk,
	getUserGroupThunk,
} from './groupThunk';

const initialState = {
	groupTag: '',
	groupIdByTag: '',
	isLoading: false,
	isError: false,
	isSuccess: false,
	geolocation: '',
	geolocGroup: [],
	publicGroup: [],
	userGroup: [],
	httpError: '',
};

export const addGroupByTag = createAsyncThunk('group/getGroupIdByTag', addGroupByTagThunk);

export const addGroupById = createAsyncThunk('group/addGroupById', addGroupByIdThunk);

export const findGroupByGeoloc = createAsyncThunk(
	'group/findGroupByGeoloc',
	findGroupByGeolocThunk
);

export const findPublicGroup = createAsyncThunk('group/findPublicGroup', findPublicGroupThunk);

export const getUserGroup = createAsyncThunk('group/getUserGroup', getUserGroupThunk);

export const deleteUserGroup = createAsyncThunk('group/deleteUserGroup', deleteUserGroupThunk);

export const checkInternalGroupAccess = createAsyncThunk(
	'group/checkInternalGroupAccess',
	checkInternalGroupAccessThunk
);

export const groupSlice = createSlice({
	name: 'group',
	initialState,
	reducers: {
		getGroupTag: (state, action) => {
			state.groupTag = action.payload;
		},
		resetIsSuccess: (state) => {
			state.isSuccess = false;
		},
		reset: (state) => {
			state = initialState;
		},
		getUserDataLoc: (state, { payload }) => {
			state.geolocation = [payload.latitude, payload.longitude];
		},
	},
	extraReducers: {
		[addGroupByTag.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.isSuccess = true;
			payload.status && toast.success(t('toast.add_gp'));
			payload.already_added && toast.warning(t('toast.already_gp'));
		},
		[addGroupByTag.pending]: (state) => {
			state.isLoading = true;
		},
		[addGroupByTag.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			toast.error(t('toast.wrong_code'));
		},
		[addGroupById.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.isSuccess = true;
			payload.status && toast.success(t('toast.add_gp'));
			payload.already_added && toast.warning(t('toast.already_in_gp'));
		},
		[addGroupById.pending]: (state) => {
			state.isLoading = true;
		},
		[addGroupById.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			toast.error(t('toast.error'));
		},
		[findGroupByGeoloc.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			const { groups } = payload;
			groups != undefined &&
				groups.length > 0 &&
				(state.geolocGroup = groups.filter(
					(gp) => !state.userGroup.some((usergp) => usergp.id === gp.id)
				));
		},
		[findGroupByGeoloc.pending]: (state) => {
			state.isLoading = true;
		},
		[findGroupByGeoloc.rejected]: (state) => {
			state.isLoading = false;
		},
		[findPublicGroup.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			const { groups } = payload;
			state.publicGroup = groups?.filter(
				(gp) => !state.userGroup.some((usergp) => usergp.id === gp.id)
			);
		},
		[findPublicGroup.pending]: (state) => {
			state.isLoading = true;
		},
		[findPublicGroup.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
		},
		[getUserGroup.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			const filteredGroups = payload.groups.filter((gp) => gp.name !== 'Vooter');
			state.userGroup = filteredGroups;
		},
		[getUserGroup.pending]: (state) => {
			state.isLoading = true;
		},
		[getUserGroup.rejected]: (state, { payload }) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.httpError = payload;
			toast.error(t('toast.error'));
		},
		[deleteUserGroup.fulfilled]: () => {
			toast.info(t('toast.leave_gp'));
		},
		[deleteUserGroup.rejected]: () => {
			toast.error(t('toast.error'));
		},
	},
});

export const { getGroupTag, resetIsSuccess, reset, getUserDataLoc } = groupSlice.actions;
export default groupSlice.reducer;
