import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { addConsultationIdToLocalStorage, addCurrentGroupToLocalStorage, getConsultationIdFromLocalStorage, getCurrentGroupFromLocalStorage } from '../../utils/localstorage';
import { getDiffusionsThunk, getConsultationThunk, postAnswersThunk, postTrashCardThunk, getCardsThunk } from './diffusionServices';

const idConsultation = getConsultationIdFromLocalStorage();
const currentGp = getCurrentGroupFromLocalStorage();

const initialState = {
	currentGroup: currentGp != null ? currentGp : null,
	feed: [],
	liveDiffusion: null,
	liveEvent: null,
	consultation_id: idConsultation != null ? idConsultation : null,
	isTest: false,
	cards: [],
	card: [],
	selected: [],
	answers: [],
	isError: false,
	isSuccess: false,
	isLoading: false,
	message: '',
	timestampStart: '',
	time_to_answer: '',
};

export const getDiffusions = createAsyncThunk('diffusion/getDiffusions', getDiffusionsThunk);

export const getConsultation = createAsyncThunk('diffusion/getConsultation', getConsultationThunk);

export const getCards = createAsyncThunk('diffusion/getCards', getCardsThunk);

export const postAnswers = createAsyncThunk('diffusion/postAnswers', postAnswersThunk);

export const postTrashCard = createAsyncThunk('diffusion/postTrashCard', postTrashCardThunk);

export const DiffusionSlice = createSlice({
	name: 'diffusion',
	initialState,
	reducers: {
		addConsultationId: (state, { payload }) => {
			state.consultation_id = payload;
			addConsultationIdToLocalStorage(payload);
		},
		addCurrentCard: (state, { payload }) => {
			state.card = payload;
		},
		//handle format answers by type
		addAnswer: (state, { payload }) => {
			const { card_type_id } = state.card;
			if (card_type_id == '4') {
				let { comment, selected } = payload;
				const cardAnswer = { value: 1, card_choice_id: selected, user_comment: comment };
				state.answers = [cardAnswer];
			}
			if (card_type_id == '3') {
				state.answers = payload;
			}
			if (card_type_id == '5') {
				state.card.card_choice.map((el) => (state.answers = [...state.answers, { value: 1, card_choice_id: el.id, user_comment: '' }]));
			}
			if (card_type_id == '2') {
				state.answers = [{ value: 1, card_choice_id: state.selected, user_comment: '' }];
			}
		},
		formatAnswers: (state, { payload }) => {
			state.answers = [...payload];
		},
		addAnswerAlt: (state, { payload }) => {
			let answer = { value: 1, card_choice_id: payload, user_comment: '' };
			state.answers = [answer];
		},
		addCurrentGroup: (state, { payload }) => {
			state.currentGroup = payload;
			addCurrentGroupToLocalStorage(payload);
		},
		isTesting: (state, { payload }) => {
			state.isTest = payload;
		},
		//handle selected answers type 2 & 5
		addSelected: (state, { payload }) => {
			const { card_type_id, choices_limit_number } = state.card;
			if (card_type_id == '2') {
				state.selected = payload;
			}
			if (card_type_id == '5') {
				if (state.selected.find((searchId) => searchId == payload)) {
					state.selected = state.selected.filter((it) => it !== payload);
				} else {
					if (choices_limit_number != null) {
						if (state.selected.length < choices_limit_number) {
							state.selected = [...state.selected, payload];
						} else {
							toast.warning(t('toast.max_res'));
						}
					} else {
						state.selected = [...state.selected, payload];
					}
				}
			}
		},
		//Select automatically the card if comment for card type 5
		addComment: (state, { payload }) => {
			state.selected.length === 0 && state.selected == payload.id;
		},
		//add comment to answer
		addCommentToAnswer: (state, { payload }) => {
			const { card_type_id } = state.card;
			const { comment, id } = payload;

			// card_type_id == '2' && (state.answers = { ...state.answers, user_comment: comment });
			(card_type_id == '5' || card_type_id == '2') && (state.answers = state.answers.map((el) => (el.card_choice_id === id ? { ...el, user_comment: comment } : el)));
		},
		startTimestamp: (state) => {
			state.timestampStart = Date.now();
		},
		addTimeToAnswer: (state, { payload }) => {
			state.time_to_answer = payload;
		},

		resetSelected: (state) => {
			state.selected = [];
		},
		resetAnswers: (state) => {
			state.answers = [];
		},

		resetDiffusion: (state) => initialState,
	},
	extraReducers: {
		[getDiffusions.pending]: (state) => {
			state.isLoading = true;
		},
		[getDiffusions.fulfilled]: (state, { payload }) => {
			let diffusions = [];
			let events = [];
			let diffs = payload.diffusions;
			const { yob, gender, groups_count } = payload;

			const consultationGenderYob = {
				id: 'gyid',
				name: 'Faisons Connaissance !',
				card_nb: 2,
				media: {
					folder: 'consultation_images',
					filename: '_gy.jpg',
					base_url: 'https://storage.googleapis.com/vooter-bucket-prod/consultation_images/_gy.jpg',
				},
				group: {
					name: 'Vooter',
					logo_media: {
						name: 'Vooter',
						folder: 'group_icons',
						filename: 'vooterCommunity_new.png',
						is_static: 1,
						format: 'PNG',
						base_url: 'https://storage.googleapis.com/vooter-bucket-prod/group_icons/vooterCommunity_new.png',
					},
				},
				transparency: false,
			};

			diffs.map((item) => {
				if (item?.type === 'consultation') {
					let { object } = item;
					diffusions.push(object);
				}
				if (item?.type === 'event') {
					let { object } = item;
					events.push(object);
				}
			});
			//vooter group by default
			if ((gender == null || yob == null) && groups_count >= 2) {
				diffusions = [consultationGenderYob, ...diffusions];
			}
			state.isLoading = false;
			state.isSuccess = true;
			state.feed = payload.diffusions;
			state.liveDiffusion = diffusions;
			state.liveEvent = events;
		},
		[getDiffusions.rejected]: (state, { payload }) => {
			state.isLoading = false;
			state.isError = true;
			state.message = payload;
		},
		[getConsultation.pending]: (state) => {
			state.isLoading = true;
		},
		[getConsultation.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.consultation = action.payload;
		},
		[getConsultation.rejected]: (state, { payload }) => {
			state.isLoading = false;
			state.isError = true;
			state.message = payload;
		},
		//cards
		[getCards.pending]: (state) => {
			state.isLoading = true;
		},
		[getCards.fulfilled]: (state, { payload }) => {
			let cards = payload;

			state.isLoading = false;
			state.isSuccess = true;

			state.cards = cards;
		},
		[getCards.rejected]: (state, { payload }) => {
			state.isLoading = false;
			state.isError = true;
			state.message = payload;
		},
		//answer
		[postAnswers.pending]: (state) => {
			state.isLoading = true;
		},
		[postAnswers.fulfilled]: (state) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.answers = [];
		},
		[postAnswers.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.message = action.payload;
		},
	},
});

export const { addAnswer, resetAnswers, addTimeToAnswer, formatAnswers, isTesting, startTimestamp, addConsultationId, resetDiffusion, addCurrentCard, addCurrentGroup, addAnswerAlt, addSelected, addComment, addCommentToAnswer, resetSelected } =
	DiffusionSlice.actions;

export default DiffusionSlice.reducer;
