import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import back from '../assets/icon_arrow_back_grey.svg';

import { AnimatePresence, motion } from 'framer-motion';
import ModalConfirm from '../components/groups/ModalConfirm';
import Overlay from '../components/UI/Overlay';
import { openModalConfirm } from '../features/ui/uiSlice';

import { useEffect } from 'react';
import logoFb from '../assets/vooter.svg';

const Groups = () => {
	const { userGroup } = useSelector((state) => state.group);
	const { isOverlayShow } = useSelector((state) => state.ui);
	const { t } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		userGroup.length < 1 && navigate('/m/');
	}, [userGroup]);

	return (
		<StyledListGroups
			as={motion.div}
			animate={{ opacity: 1 }}
			initial={{ opacity: 0 }}
			exit={{ opacity: 0 }}
		>
			<Link to="/m/" className="back">
				<img src={back} alt="return" />
			</Link>
			<h3>{t('group.title')}</h3>
			{userGroup.map((group) => (
				<Group key={group.id} {...group} />
			))}
			<AnimatePresence>{isOverlayShow && <Overlay />}</AnimatePresence>
		</StyledListGroups>
	);
};

const Group = ({ name, id, logo_media }) => {
	const dispatch = useDispatch();
	const { isOpenModalConfirm, groupId } = useSelector((state) => state.ui);
	const { t } = useTranslation();
	const handleClick = (id) => {
		dispatch(openModalConfirm(id));
	};

	return (
		<StyledListGroup>
			<div className="group_logo">
				<img src={logo_media.base_url || logoFb} alt="group_logo" />
			</div>
			<div className="group_name">{name}</div>
			<button onClick={() => handleClick(id)}>{t('group.leave')}</button>
			{isOpenModalConfirm && groupId == id && <ModalConfirm id={id} name={name} />}
		</StyledListGroup>
	);
};

const StyledListGroups = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	position: relative;
	.back {
		height: 50px;
		width: 50px;
		position: absolute;
		left: 0.5rem;
		top: 0.5rem;
	}
	h3 {
		margin: 1rem 0;
	}
`;

const StyledListGroup = styled.article`
	display: flex;
	width: 75%;
	justify-content: flex-start;
	align-items: center;
	gap: 1rem;
	.group_logo {
		width: 45px;
		height: 45px;
		border: 1px solid var(--light-grey);
		background-color: white;
		border-radius: 5px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.group_name {
		flex: 1;
	}
	button {
		background: var(--vooter-pink);
		color: white;
		padding: 0.4rem 1rem;
		border-radius: 5px;
		border: none;
	}
`;

export default Groups;
