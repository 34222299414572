import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetAppToken } from '../hooks/useGetAppToken';
import Login from './Login';
import Signup from './Signup';

export const ExternalGroupAccess = () => {
	const { ega_token } = useParams();
	const { response, loading } = useGetAppToken();
	const [data, setData] = useState({ result: 0 });

	useEffect(() => {
		if (!loading && response?.access_token) {
			axios({
				method: 'get',
				params: { access_token: response.access_token },
				url: `${process.env.REACT_APP_API_URL}/group-external-user-access/${ega_token}`,
				responseType: 'json',
				headers: { 'Content-Type': 'application/json' },
			}).then((res) => {
				setData(res.data);
			});
		}
	}, [loading, response?.access_token, ega_token]);

	switch (data.result) {
		case 0:
		case 1:
			return <Login />;
		case 2:
			return <Signup emailByDefault={data.email} />;
	}
};
