import { createSlice } from '@reduxjs/toolkit';
import { addCardIndexToLocalStorage, addNumberOfCardsToLocalStorage, getCardIndexFromLocalStorage, getNumberOfCardsFromLocalStorage, removeNumberOfCardsFromLocalStorage, resetCardIndexFromLocalStorage } from '../../utils/localstorage';

const nbOfCards = getNumberOfCardsFromLocalStorage();
const cardI = getCardIndexFromLocalStorage();

const initialState = {
	isSideMenuOpen: false,
	isOverlayShow: false,
	isMenuPlusOpen: false,
	isOpenModalDiffusionInfo: false,
	isOpenModalDetail: false,
	isOpenModalNoComment: false,
	isOpenModalExtraChoice: false,
	isOpenModalConfirm: false,
	isAccountOpen: false,
	isCommentModalOpen: false,
	modalType: '',
	diffusionId: '',
	groupId: '',
	choiceId: '',
	cardBack: false,
	cardIndex: cardI !== null ? cardI : 1,
	numberOfCards: nbOfCards != null ? nbOfCards : 1,
	isEnd: false,
	isValue: false,
	isMove: false,
};

export const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		openSideMenu: (state) => {
			state.isOverlayShow = true;
			state.isSideMenuOpen = true;
		},
		closeSideMenu: (state) => {
			state.isSideMenuOpen = false;
			state.isOverlayShow = false;
		},
		openAccount: (state) => {
			state.isAccountOpen = true;
			state.isSideMenuOpen = false;
		},
		closeAccount: (state) => {
			state.isAccountOpen = false;
			state.isOverlayShow = false;
		},
		showOverlay: (state) => {
			state.isOverlayShow = true;
		},
		hideOverlay: (state) => {
			state.isOverlayShow = false;
			state.isMenuPlusOpen = false;
			state.isSideMenuOpen = false;
			state.isOpenModalDiffusionInfo = false;
			state.isOpenModalDetail = false;
			state.isOpenModalNoComment = false;
			state.isOpenModalExtraChoice = false;
			state.isAccountOpen = false;
			state.isCommentModalOpen = false;
			state.isOpenModalConfirm = false;
		},
		openMenuPlus: (state) => {
			state.isOverlayShow = true;
			state.isMenuPlusOpen = true;
		},
		closeMenuPlus: (state) => {
			state.isOverlayShow = false;
			state.isMenuPlusOpen = false;
		},
		openModalDiffusionInfo: (state, { payload }) => {
			state.modalType = payload.type;
			state.diffusionId = payload.id;
			state.isOpenModalDiffusionInfo = true;
			state.isOverlayShow = true;
		},
		closeModalDiffusionInfo: (state) => {
			state.isOpenModalDiffusionInfo = false;
			state.isOverlayShow = false;
			state.modalType = '';
			state.diffusionId = '';
		},
		showCardBack: (state) => {
			state.cardBack = true;
		},
		showCardFront: (state) => {
			state.cardBack = false;
		},
		showNextCard: (state) => {
			state.cardBack = false;
			if (state.cardIndex < state.numberOfCards || state.cardIndex === 0) {
				state.cardIndex++;
				addCardIndexToLocalStorage(state.cardIndex);
			}
			if (state.cardIndex === state.numberOfCards) {
				state.isEnd = true;
			}
		},
		openModalDetail: (state) => {
			state.isOpenModalDetail = true;
			state.isOverlayShow = true;
		},
		closeModalDetail: (state) => {
			state.isOpenModalDetail = false;
			state.isOverlayShow = false;
		},
		openModalNoComment: (state) => {
			state.isOpenModalNoComment = true;
			state.isOverlayShow = true;
		},
		closeModalNoComment: (state) => {
			state.isOpenModalNoComment = false;
			state.isOverlayShow = false;
		},
		openModalExtraChoice: (state) => {
			state.isOpenModalExtraChoice = true;
			state.isOverlayShow = true;
		},
		closeModalExtraChoice: (state) => {
			state.isOpenModalExtraChoice = false;
			state.isOverlayShow = false;
		},
		openModalComment: (state) => {
			state.isCommentModalOpen = true;
			state.isOverlayShow = true;
		},
		closeModalComment: (state) => {
			state.isCommentModalOpen = false;
			state.isOverlayShow = false;
		},
		openModalConfirm: (state, { payload }) => {
			state.isOpenModalConfirm = true;
			state.groupId = payload;
			state.isOverlayShow = true;
		},
		closeModalConfirm: (state) => {
			state.isOpenModalConfirm = false;
			state.groupId = '';
			state.isOverlayShow = false;
		},
		setValue: (state, { payload }) => {
			state.isValue = payload;
		},
		setMove: (state) => {
			state.isMove = true;
		},
		resetMove: (state) => {
			state.isMove = false;
		},
		setCardsNumber: (state, { payload }) => {
			addNumberOfCardsToLocalStorage(payload);
			state.numberOfCards = payload;
		},
		addChoiceId: (state, { payload }) => {
			state.choiceId = payload;
		},
		reset: () => {
			removeNumberOfCardsFromLocalStorage();
			resetCardIndexFromLocalStorage();
			return initialState;
		},
	},
});

export const {
	openModalNoComment,
	closeModalNoComment,
	setValue,
	openModalDetail,
	closeModalDetail,
	openSideMenu,
	closeSideMenu,
	hideOverlay,
	openMenuPlus,
	closeMenuPlus,
	openModalDiffusionInfo,
	closeModalDiffusionInfo,
	openModalExtraChoice,
	closeModalExtraChoice,
	showCardBack,
	showCardFront,
	showNextCard,
	setCardsNumber,
	reset,
	openAccount,
	setMove,
	resetMove,
	openModalComment,
	closeModalComment,
	addChoiceId,
	openModalConfirm,
	closeModalConfirm,
} = uiSlice.actions;

export default uiSlice.reducer;
