import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { logout } from '../../features/auth/authSlice';

const Account = () => {
	const { t } = useTranslation();
	const [email, setEmail] = useState('');
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const logoutUser = () => {
		navigate('/m/login');
		dispatch(logout());
	};

	useEffect(() => {
		const mail = localStorage.getItem('email');
		setEmail(mail);
	}, []);

	return (
		<StyledAccount>
			<div className="wrap">
				<h6>{t('home.account.account')}</h6>
				<p>{t('home.account.id')}</p>
				<p className="mail">{email}</p>
				<button className="btn btnLogout" onClick={() => logoutUser()}>
					{t('home.account.logout')}
				</button>
			</div>
		</StyledAccount>
	);
};

const StyledAccount = styled.div`
	height: 100%;
	width: 85%;
	background-color: white;
	padding: 2% 4%;
	position: absolute;
	z-index: 1000;
	font-size: 18px;
	text-align: center;
	.wrap {
		height: 13rem;
		display: flex;
		justify-content: space-around;
		flex-direction: column;
		align-items: center;
	}
	h6 {
		font-size: 1.1rem;
	}
	p {
		font-size: 1rem;
	}
	.mail {
		font-weight: 600;
		color: var(--dark-blue);
	}
	.btnLogout {
		background: var(--vooter-blue);
		color: white;
		width: 80%;
	}
`;

export default Account;
