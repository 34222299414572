import axios from 'axios';
import customFetch from '../../utils/axios';
import { getTokenAppFromLocalStorage } from '../../utils/localstorage';
import { getTokenApp } from './authSlice';

export const registerThunk = async (userData, thunkAPI) => {
	const auth = { username: process.env.REACT_APP_API_ID, password: process.env.REACT_APP_API_KEY };
	await thunkAPI.dispatch(getTokenApp());
	const tokenApp = getTokenAppFromLocalStorage();
	try {
		const response = await axios({
			method: 'post',
			data: {
				email: userData.email,
				password: userData.password,
				locale_id: 2,
				crea_model: 'mobile',
				origin: 'voteapp',
				iga: userData.iga_token,
				ega: userData.ega_token,
			},
			url: `${process.env.REACT_APP_API_URL}/user/?access_token=${tokenApp}`,
			responseType: 'json',
			headers: { 'Content-Type': 'application/json' },
		});

		const response2 = await customFetch.post(
			'/oauth/token',
			{
				username: userData.email,
				password: userData.password,
				grant_type: 'password',
			},
			{ 'Content-Type': 'application/json', auth: auth }
		);
		localStorage.setItem('email', userData.email);
		return [response.data, response2.data];
	} catch {
		console.log('catch error registerThunk');
		return thunkAPI.rejectWithValue(error.response.data);
	}
};

export const loginThunk = async (userData, thunkAPI) => {
	const auth = { username: process.env.REACT_APP_API_ID, password: process.env.REACT_APP_API_KEY };
	await thunkAPI.dispatch(getTokenApp());

	try {
		const response = await customFetch.post(
			'/oauth/token',
			{
				username: userData.email,
				password: userData.password,
				grant_type: 'password',
			},
			{ 'Content-Type': 'application/json', auth: auth }
		);
		localStorage.setItem('email', userData.email);
		return response.data;
	} catch (error) {
		console.log(error.response.data);
		return thunkAPI.rejectWithValue(error.response.data);
	}
};

export const getTokenAppThunk = async (_, thunkAPI) => {
	const auth = { username: process.env.REACT_APP_API_ID, password: process.env.REACT_APP_API_KEY };
	try {
		const response = await customFetch.post(
			'/oauth/token',
			{
				grant_type: 'client_credentials',
			},
			{ 'Content-Type': 'application/json', auth: auth }
		);

		return response.data;
	} catch (error) {
		console.log('catch error', error.response.data.message);
		return thunkAPI.rejectWithValue(error.response.data.message);
	}
};

export const getUserThunk = async (_, thunkAPI) => {
	//user token
	const access_token = localStorage.getItem('user').access_token;

	try {
		const response = await customFetch.get(
			`/user/${thunkAPI.getState().auth.user_token.user_id}`,
			{
				params: { access_token: access_token },
			}
		);

		return response.data;
	} catch (error) {
		console.log('catch error', error.response.data.message);
		return thunkAPI.rejectWithValue(error.response.data.message);
	}
};

export const updateUserThunk = async (_, thunkAPI) => {
	//user token
	const access_token = localStorage.getItem('user').access_token;

	let yob = await thunkAPI.getState().auth.user_params.yob;
	let gender = await thunkAPI.getState().auth.user_params.gender;

	if (yob != null && gender != null) {
		try {
			const res = await axios({
				method: 'put',
				data: { yob: yob.substring(3), gender: gender.slice(-1) },
				url: `${process.env.REACT_APP_API_URL}/user/1?access_token=${access_token}`,
				responseType: 'json',
				headers: { 'Content-Type': 'application/json' },
			});

			return res.data;
		} catch (error) {
			if (error.response) {
				console.log(error.response.data);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
			return thunkAPI.rejectWithValue('error');
		}
	} else return;
};
