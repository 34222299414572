import axios from 'axios';
import { getTokenApp } from '../auth/authSlice';
import { closeMenuPlus } from '../ui/uiSlice';

export const addGroupByTagThunk = async (_, thunkAPI) => {
	const tag = thunkAPI.getState().group.groupTag;

	//user token
	const token = JSON.parse(localStorage.getItem('user')).access_token;

	//tokenApp
	const {
		payload: { access_token },
	} = await thunkAPI.dispatch(getTokenApp());
	try {
		const res = await axios({
			method: 'get',
			params: { access_token: access_token },
			url: `${process.env.REACT_APP_API_URL}/search-group/${tag}`,
			responseType: 'json',
			headers: { 'Content-Type': 'application/json' },
		});
		res.data.error && 'error';
		const groupId = await res.data.group.id;
		const res2 = await axios({
			method: 'post',
			data: { acces_token: token, group_id: groupId },
			url: `${process.env.REACT_APP_API_URL}/group-user/?access_token=${token}`,
			responseType: 'json',
			headers: { 'Content-Type': 'application/json' },
		});
		thunkAPI.dispatch(closeMenuPlus());
		return res2.data;
	} catch (err) {
		console.log(err);
		return thunkAPI.rejectWithValue(err.response.data.message);
	}
};

export const addGroupByIdThunk = async (id, thunkAPI) => {
	//user token
	const token = JSON.parse(localStorage.getItem('user')).access_token;

	const res = await axios({
		method: 'post',
		data: { acces_token: token, group_id: id },
		url: `${process.env.REACT_APP_API_URL}/group-user/?access_token=${token}`,
		responseType: 'json',
		headers: { 'Content-Type': 'application/json' },
	});
	return res.data;
};

export const findGroupByGeolocThunk = async (_, thunkAPI) => {
	const {
		payload: { access_token },
	} = await thunkAPI.dispatch(getTokenApp());
	const geoloc = thunkAPI.getState().group.geolocation;
	try {
		const res = await axios({
			method: 'get',
			params: { access_token: access_token, lat: geoloc[0], long: geoloc[1] },
			url: `${process.env.REACT_APP_API_URL}/search-group/`,
			responseType: 'json',
			headers: { 'Content-Type': 'application/json' },
		});

		return res.data;
	} catch (err) {
		console.log(err);
		return thunkAPI.rejectWithValue(err.response.data.message);
	}
};

export const findPublicGroupThunk = async (_, thunkAPI) => {
	const {
		payload: { access_token },
	} = await thunkAPI.dispatch(getTokenApp());

	try {
		const res = await axios({
			method: 'get',
			params: { access_token: access_token, public: 1 },
			url: `${process.env.REACT_APP_API_URL}/search-group/`,
			responseType: 'json',
			headers: { 'Content-Type': 'application/json' },
		});
		return res.data;
	} catch (err) {
		console.log(err);
		return thunkAPI.rejectWithValue(err.response.data.message);
	}
};

export const getUserGroupThunk = async (_, thunkAPI) => {
	//user token
	const token = JSON.parse(localStorage.getItem('user')).access_token;
	const user_id = JSON.parse(localStorage.getItem('user')).user_id;

	try {
		const res = await axios({
			method: 'get',
			params: { access_token: token, user_id: user_id, acl: '1,2,3,4,5,6' },
			url: `${process.env.REACT_APP_API_URL}/group/`,
			responseType: 'json',
			headers: { 'Content-Type': 'application/json' },
		});

		return res.data;
	} catch (err) {
		console.log(err.response.data.message);

		return thunkAPI.rejectWithValue(err.response.status);
	}
};

export const deleteUserGroupThunk = async (group_id, thunkAPI) => {
	const token = JSON.parse(localStorage.getItem('user')).access_token;

	try {
		const res = await axios({
			method: 'delete',
			params: { access_token: token },
			url: `${process.env.REACT_APP_API_URL}/group-user/${group_id}`,
			responseType: 'json',
			headers: { 'Content-Type': 'application/json' },
		});
		return res.data;
	} catch (err) {
		console.log(err);
		return thunkAPI.rejectWithValue(err.response.data.message);
	}
};

export const checkInternalGroupAccessThunk = async (token, thunkAPI) => {
	//tokenApp
	const {
		payload: { access_token },
	} = await thunkAPI.dispatch(getTokenApp());
	try {
		const res = await axios({
			method: 'get',
			params: { access_token: access_token },
			url: `${process.env.REACT_APP_API_URL}/group-internal-user-access/${token}`,
			responseType: 'json',
			headers: { 'Content-Type': 'application/json' },
		});

		return res.data;
	} catch (err) {
		console.log(err);
		return thunkAPI.rejectWithValue(err.response.data.message);
	}
};
