import styled from 'styled-components';
import apple from '../../assets/icon_app_store.svg';
import google from '../../assets/icon_google_play.svg';

const ButtonLinksAppMobil = () => {
	return (
		<StyledButtonLinks>
			<a className="btn" href="http://onelink.to/vooter">
				<img src={apple} alt="" /> <span>Google Play</span>
			</a>
			<a className="btn" href="http://onelink.to/vooter">
				<img src={google} alt="" /> <span>App Store</span>
			</a>
		</StyledButtonLinks>
	);
};

export default ButtonLinksAppMobil;

const StyledButtonLinks = styled.section`
	display: flex;
	flex-direction: column;
	height: 6rem;
	width: 90%;
	justify-content: flex-start;
	gap: 5%;
	align-items: center;
	margin: 2% auto;
	a {
		position: relative;
		border: 1px solid var(--light-grey);
		width: 100%;
		color: var(--general);
		img {
			position: absolute;
			left: 1rem;
		}
	}
`;
