import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AddGroupByCode from './AddGroupByCode';
import MenuPlusGroupList from './MenuPlusGroupList';

const MenuPlus = () => {
	const { t } = useTranslation();
	const { isMenuPlusOpen } = useSelector((state) => state.ui);
	return (
		isMenuPlusOpen && (
			<StyledMenuPlus
				as={motion.div}
				animate={{ opacity: 1 }}
				initial={{ opacity: 0 }}
				exit={{ opacity: 0 }}
			>
				<div className="menu-multi">
					<MenuPlusGroupList />
					<AddGroupByCode />
					<Link to="/m/message">
						<button className="btn create create-group">
							{t('home.menuplus.create_gp')}
						</button>
					</Link>
					<Link to="/m/message">
						<button className="btn create create-report">{t('home.menuplus.mir')}</button>
					</Link>
					<Link to="/m/message">
						<button className="btn create create-diffusion">
							{t('home.menuplus.create_diff')}
						</button>
					</Link>
				</div>
			</StyledMenuPlus>
		)
	);
};

const StyledMenuPlus = styled.div`
	width: 90%;
	position: relative;
	display: flex;
	justify-content: center;
	z-index: 999;
	margin: 0 auto;
	a {
		width: 100%;
		button {
			width: 100%;
			height: 40px;
			cursor: pointer;
		}
	}
	.menu-multi {
		position: fixed;
		margin: 0 auto;
		width: 95%;
		max-width: 500px;
		height: auto;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		margin-top: 20%;
		font-size: var(--fsui);
	}
	.create {
		color: white;
		min-height: 40px;
		max-width: 100%;
		&-diffusion {
			background: var(--vooter-pink);
		}
		&-group {
			background: var(--vooter-green);
		}
		&-report {
			background: var(--vooter-blue);
		}
	}
`;

export default MenuPlus;
