import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Question from './Question';
import QuestionTop from './QuestionTop';
import Theme from './Theme';
import ChoicesTypeOne from './choices/ChoicesTypeOne';
import Footer from '../UI/Footer';
import FooterRecto from './FooterRecto';
import ChoicesTypeTwo from './choices/ChoicesTypeTwo';
import FooterVerso from './FooterVerso';
import ChoicesTypeFour from './choices/ChoicesTypeFour';
import ChoicesTypeThree from './choices/ChoicesTypeThree';
import ChoicesTypeFive from './choices/ChoicesTypeFive';
import Test from '../UI/Test';
import Loader from '../UI/Loader';
import { motion } from 'framer-motion';

const Card = ({ card }) => {
	const cardBack = useSelector((state) => state.ui.cardBack);
	const { isTest, isLoading } = useSelector((state) => state.diffusion);

	isLoading && <Loader />;
	return cardBack ? (
		//backCard
		<StyledCard as={motion.div} animate={{ opacity: 1, x: 0 }} initial={{ opacity: 0, x: -400 }} transition={{ type: 'just' }} exit={{ opacity: 0 }}>
			<QuestionTop question={card.issue} type="info" about={card.about} />
			{card.card_type_id === '1' && (
				<>
					<ChoicesTypeOne card={card} />
					<Footer primary>
						<FooterVerso type={card.card_type_id} />
					</Footer>
				</>
			)}
			{card.card_type_id === '2' && (
				<>
					<ChoicesTypeTwo card={card} />
					<Footer secondary>
						<FooterVerso type={card.card_type_id} extra={card.card_choice_extra} />
					</Footer>
				</>
			)}
			{card.card_type_id === '3' && (
				<>
					<ChoicesTypeThree card={card} />
					<Footer secondary type={card.card_type_id}>
						<FooterVerso type={card.card_type_id} />
					</Footer>
				</>
			)}
			{card.card_type_id === '4' && (
				<>
					<ChoicesTypeFour card={card} />
					<Footer secondary>
						<FooterVerso type={card.card_type_id} />
					</Footer>
				</>
			)}
			{card.card_type_id === '5' && (
				<>
					<ChoicesTypeFive card={card} />
					<Footer secondary>
						<FooterVerso type={card.card_type_id} extra={card.card_choice_extra} />
					</Footer>
				</>
			)}
		</StyledCard>
	) : (
		//frontcard
		<StyledCard2
			as={motion.div}
			animate={{ opacity: 1, x: 0 }}
			initial={{ opacity: 0, x: -400 }}
			transition={{ type: 'spring', duration: 0.5, delay: 0.3 }}
			exit={{ opacity: 0 }}
			style={{ background: `url(${card.background_media.medium_url}) center/cover no-repeat` }}
		>
			<Theme theme={card.theme.theme_value} />
			<Question question={card.issue} about={card.about} />
			<Footer>
				<FooterRecto />
			</Footer>
			{isTest && <Test />}
		</StyledCard2>
	);
};

const StyledCard = styled.div`
	height: 90%;
	display: flex;
	flex-direction: column;
	justify-content: flex;
	align-items: center;
	position: relative;
	justify-content: space-between;
	flex: 1;
`;

const StyledCard2 = styled.div`
	height: 90%;
	display: flex;
	flex-direction: column;
	justify-content: flex;
	align-items: center;
	position: relative;
	justify-content: space-between;
	flex: 1;
`;

export default Card;
