import { useAxios } from './useAxios';

export const useGetAppToken = () => {
	const auth = { username: process.env.REACT_APP_API_ID, password: process.env.REACT_APP_API_KEY };

	const { response, error, loading } = useAxios({
		url: '/oauth/token',
		method: 'post',
		body: { 'Content-Type': 'application/json', auth: auth },
		headers: { grant_type: 'client_credentials' },
	});

	return { response, error, loading };
};
