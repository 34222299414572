import styled from 'styled-components';

const Footer = (props) => {
	return <StyledFooter primary={props.primary}>{props.children}</StyledFooter>;
};

const StyledFooter = styled.footer`
	width: 100%;
	background-color: ${(props) => (props.primary ? 'var(--dark-blue)' : props.secondary ? 'white' : 'transparent')};
	color: ${(props) => (props.primary ? 'white' : 'var(--dark-blue)')};
	height: 11%;
	min-height: 2.9rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	cursor: pointer;
	transition: background-color 0.3s;
	&:hover {
		background-color: ${(props) => (props.primary ? 'var(--dark-blue2)' : props.secondary ? '#eaeaea' : 'transparent')};
	}
`;

export default Footer;
