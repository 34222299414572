import styled, { keyframes } from 'styled-components';

const loaderAnimationDots1 = keyframes`
       	0% {
		transform: translateX(0px) scale(1);
		background: var(--vooter-blue);
	}
	15% {
		transform: translateX(30px) scale(1.4);
		background: var(--vooter-blue);
	}
	25% {
		transform: translateX(65px) scale(2);
		background: var(--vooter-blue);
	}
	35% {
		transform: translateX(30px) scale(1.4);
		background: var(--vooter-green);
	}
	50% {
		transform: translateX(0px) scale(1);
		background: var(--vooter-green);
	}
	65% {
		transform: translateX(30px) scale(1.4);
		background: var(--vooter-orange);
	}
	75% {
		transform: translateX(65px) scale(2);
		background: var(--vooter-orange);
	}
	85% {
		transform: translateX(30px) scale(1.4);
		background: var(--vooter-blue);
	}
	100% {
		transform: translateX(0px) scale(1);
		background: var(--vooter-blue);
	}`;

const loaderAnimationDots2 = keyframes`
0% {
		transform: translateX(0px) scale(1);
		background: var(--vooter-orange);
	}
	15% {
		transform: translateX(-30px) scale(1.4);
		background: var(--vooter-orange);
	}
	25% {
		transform: translateX(-65px) scale(2);
		background: var(--vooter-orange);
	}
	30% {
		transform: translateX(-30px) scale(1.4);
		background: var(--vooter-blue);
	}
	50% {
		transform: translateX(0px) scale(1);
		background: var(--vooter-blue);
	}
	65% {
		transform: translateX(-30px) scale(1.4);
		background: var(--vooter-green);
	}
	75% {
		transform: translateX(-65px) scale(2);
		background: var(--vooter-green);
	}
	85% {
		transform: translateX(-30px) scale(1.4);
		background: var(--vooter-orange);
	}
	100% {
		transform: translateX(0px) scale(1);
		background: var(--vooter-orange);
	}`;

const StyledLoader = styled.section`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10%;
	.wrap-loader {
		height: 50px;
		width: 180px;
		display: block;
		position: relative;
	}

	.dots1 {
		position: absolute;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		left: 0px;
		animation: ${loaderAnimationDots1} 1.8s infinite linear;
	}

	.dots2 {
		position: absolute;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		left: 150px;
		animation: ${loaderAnimationDots2} 1.8s infinite linear;
	}
`;

const Loader = () => {
	return (
		<StyledLoader>
			<div className="wrap-loader">
				<span className="dots1"></span>
				<span className="dots2"></span>
			</div>
		</StyledLoader>
	);
};

export default Loader;
