import next from '../../assets/icon_arrow_next_blue.svg';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { showCardBack } from '../../features/ui/uiSlice';
import { startTimestamp } from '../../features/diffusion/diffusionSlice';
import { motion } from 'framer-motion';

const FooterRecto = () => {
	const dispatch = useDispatch();
	const showBackCard = () => {
		dispatch(showCardBack());
		dispatch(startTimestamp());
	};
	return (
		<StyledFooterRecto>
			<motion.div className="next center-fl" initial={{ x: -400 }} animate={{ x: 0, transition: { type: 'spring', stiffness: 120, duration: 0.2 } }}>
				<img src={next} alt="next" onClick={showBackCard} />
			</motion.div>
		</StyledFooterRecto>
	);
};

const StyledFooterRecto = styled.div`
	width: 100%;
	position: absolute;
	bottom: 3%;
	img {
		transition: 0.3s all;
	}
	img:hover {
		transform: scale(1.2);
	}
`;

export default FooterRecto;
