import styled from 'styled-components';
import { useSelector } from 'react-redux';
import DiffusionItem from './DiffusionItem';
import ModalInfo from './Modal/ModalInfo';

const DiffusionsList = () => {
	const { isOpenModalDiffusionInfo, diffusionId } = useSelector((state) => state.ui);
	const { liveDiffusion, liveEvent } = useSelector((state) => state.diffusion);

	return (
		<StyledListDiffusion>
			{liveDiffusion?.map((diffusion) => {
				return (
					<div key={diffusion.id} className="center-fl">
						<DiffusionItem diffusion={diffusion} isTest={diffusion.status == '15'} />
						{isOpenModalDiffusionInfo && diffusionId === diffusion.id && <ModalInfo diffusion={diffusion} />}
					</div>
				);
			})}
		</StyledListDiffusion>
	);
};

const StyledListDiffusion = styled.section`
	margin: 5% auto 0;
	padding-bottom: 2rem;
	width: 95%;
	display: grid;
	gap: 0.7rem;
	grid-template-columns: repeat(auto-fit, minmax(220px, 370px));
	justify-content: center;
`;

export default DiffusionsList;
