const getYears = () => {
	let date = new Date();
	let year = date.getFullYear() - 14;
	let years = [];
	let cards_choices = [];
	for (let yob = 1910; yob <= year; yob++) {
		years.push(yob);
		cards_choices.push({
			id: 'yob' + yob,
			card_id: 'yob_card_id' + yob,
			value: yob,
			type: '0',
			is_comment_allowed: null,
		});
	}

	return cards_choices;
};
//yobgender
export const genderYobCards = [
	{
		id: 'gyid_1',
		consultation_id: 'gyid',
		theme_id: '',
		card_type_id: '2',
		background_media_id: '294',
		issue: 'Etes-vous un homme ou une femme ?',
		about: null,
		call_to_action_id: null,
		call_to_action_label: null,
		call_to_action_data: null,
		is_anonymous: '1',
		is_comment_allowed: '0',
		choices_limit_number: null,
		status: '10',
		alternative_votes: '0',
		background_media: {
			base_url: 'https://storage.googleapis.com/vooter-bucket-prod/card_bg/10000.jpg',
		},
		theme: {
			theme_value: 'Genre',
		},
		card_choice: [
			{
				id: 'gender_2',
				card_id: 'gender_id_2',
				value: 'Une femme',
				type: '0',
				is_comment_allowed: null,
			},
			{
				id: 'gender_1',
				card_id: 'gender_id_1',
				value: 'Un Homme',
				type: '0',
				is_comment_allowed: null,
			},
		],
	},
	{
		id: 'gyid_2',
		consultation_id: 'gyid',
		theme_id: '',
		card_type_id: '2',
		background_media_id: '294',
		issue: 'Quelle est votre année de naissance ?',
		about: null,
		call_to_action_id: null,
		call_to_action_label: null,
		call_to_action_data: null,
		is_anonymous: '1',
		is_comment_allowed: '0',
		choices_limit_number: null,
		status: '10',
		alternative_votes: '0',
		background_media: {
			base_url: 'https://storage.googleapis.com/vooter-bucket-prod/card_bg/10000.jpg',
		},
		theme: {
			theme_value: 'Genre',
		},
		card_choice: getYears(),
	},
];
