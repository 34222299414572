import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import DiffusionsList from '../components/home/DiffusionsList';
import MenuPlus from '../components/home/MenuPlus';
import Navbar from '../components/home/Navbar';
import NoDiffusion from '../components/home/NoDiffusion';
import SideMenu from '../components/home/SideMenu';
import Loader from '../components/UI/Loader';
import Overlay from '../components/UI/Overlay';
import { logout, setIsLogin } from '../features/auth/authSlice';
import { getDiffusions, resetDiffusion } from '../features/diffusion/diffusionSlice';
import { getUserDataLoc, getUserGroup } from '../features/group/groupSlice';
import { reset } from '../features/ui/uiSlice';
import {
	getUserFromLocalStorage,
	removeConsultationIdFromLocalStorage,
	removeCurrentGroupFromLocalStorage,
} from '../utils/localstorage';

const Home = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { isOverlayShow } = useSelector((state) => state.ui);
	const { liveDiffusion, isLoading, message, isError } = useSelector((state) => state.diffusion);
	const { isLogin } = useSelector((state) => state.auth);
	const { isSuccess, httpError } = useSelector((state) => state.group);
	const user = getUserFromLocalStorage();

	useEffect(() => {
		if (user == null || user == undefined || httpError != '') {
			navigate('/m/login');
			dispatch(logout());
		} else {
			dispatch(setIsLogin());
			dispatch(reset());
			dispatch(resetDiffusion());
			removeConsultationIdFromLocalStorage();
			removeCurrentGroupFromLocalStorage();
			if (isError) {
				console.log(message);
			}
			dispatch(getDiffusions());
			dispatch(getUserGroup());
		}
	}, [isLogin]);

	useEffect(() => {
		if (navigator?.geolocation) {
			navigator.geolocation.getCurrentPosition((location) => {
				const { latitude, longitude } = location.coords;
				if (location) dispatch(getUserDataLoc({ latitude, longitude }));
			});
		}
	}, []);

	useEffect(() => {
		isSuccess && (dispatch(getDiffusions()), dispatch(getUserGroup()));
	}, [isSuccess]);

	return (
		<StyledHome
			as={motion.div}
			animate={{ opacity: 1 }}
			initial={{ opacity: 0 }}
			exit={{ opacity: 0 }}
		>
			<AnimatePresence>{isOverlayShow && <Overlay />}</AnimatePresence>
			<MenuPlus />
			<SideMenu />
			<Navbar />
			{isLoading ? <Loader /> : liveDiffusion?.length > 0 ? <DiffusionsList /> : <NoDiffusion />}
		</StyledHome>
	);
};

const StyledHome = styled.div`
	min-height: 100%;
	position: relative;
	background: white;
`;

export default Home;
