import styled from 'styled-components';
import img_hand from '../../assets/img_handphone.svg';
import { motion } from 'framer-motion';
import { Trans, useTranslation } from 'react-i18next';

const End_message = () => {
	const { t } = useTranslation();
	return (
		<>
			<StyledEndMessage>
				<div className="top-message">
					<motion.h3 initial={{ scale: 0.8 }} animate={{ scale: [1, 1.2, 1] }} transition={{ type: 'spring', duration: 0.5 }}>
						{t('info.endMessage.ty')}
					</motion.h3>
					<p>{t('info.endMessage.end')}</p>
				</div>
				<div className="image-end-message">
					<img src={img_hand} alt="" />
				</div>
				<div className="bottom-message">
					<p>
						<Trans i18nKey="info.endMessage.result_info">
							Retrouver ces informations et les résultats sur l'<strong>application Vooter</strong> !
						</Trans>
					</p>
					<p>{t('info.endMessage.app')}</p>
				</div>
			</StyledEndMessage>
		</>
	);
};

const StyledEndMessage = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	width: 85%;
	flex: 1;
	.top-message {
		width: 100%;
		text-align: center;
		h3 {
			font-size: 1.8rem;
			color: var(--vooter-blue);
			margin-bottom: 5%;
		}
		p {
			font-size: 0.9rem;
			color: var(--vooter-blue);
		}
	}
	.bottom-message {
		text-align: center;
	}
	.image-end-message {
		object-fit: contain;
		height: 35%;
		font-size: 0.8rem;
		img {
			height: 100%;
			width: 100%;
		}
	}
`;

export default End_message;
