import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { addChoiceId, openModalComment, setValue } from '../../../features/ui/uiSlice';
import AlternativeChoice from './AlternativeChoice';
import { addAnswer, addCurrentCard, resetAnswers, addSelected } from '../../../features/diffusion/diffusionSlice';

import Comment_modal from '../Comment_modal';
import iconComment from '../../../assets/icon_comment.svg';
import { useTranslation } from 'react-i18next';

const ChoicesTypeFive = ({ card }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { selected } = useSelector((state) => state.diffusion);
	const { isCommentModalOpen } = useSelector((state) => state.ui);
	const { choices_limit_number } = useSelector((state) => state.diffusion.card);

	const handleSelected = (id) => {
		dispatch(addSelected(id));
	};

	const handleComment = (id) => {
		if (!selected.includes(id)) {
			dispatch(addSelected(id));
			dispatch(openModalComment());
		} else if (selected.includes(id)) {
			if (selected.length <= choices_limit_number) {
				dispatch(openModalComment());
			}
		}
	};

	useEffect(() => {
		dispatch(addCurrentCard(card));
		dispatch(resetAnswers());
		dispatch(addAnswer());
	}, [card]);

	useEffect(() => {
		selected.length > 0 && dispatch(setValue(true));
		selected.length === 0 && dispatch(setValue(false));
	}, [selected]);

	return (
		<>
			<StyledChoices>
				{parseInt(card.choices_limit_number) > 1 && (
					<div className="max">
						{t('broadcast.choice.max')} {card.choices_limit_number}
					</div>
				)}
				{card.card_choice.map((choice) => (
					<div className={`choice ${selected.find((searchId) => searchId === choice.id) && 'selected'}`} key={choice.id}>
						<span onClick={() => !isCommentModalOpen && handleSelected(choice.id)} className={`${choice.is_comment_allowed == '1' ? 'comment' : 'noComment'}`}>
							{choice.value}
						</span>
						{choice.is_comment_allowed == '1' && (
							<>
								<div
									className="btnComment"
									onClick={() => {
										handleComment(choice.id);
										dispatch(addChoiceId(choice.id));
									}}
								>
									<img src={iconComment} />
								</div>

								{isCommentModalOpen && choice.id == selected[selected.length - 1] && <Comment_modal />}
							</>
						)}
					</div>
				))}
			</StyledChoices>
			{card.alternative_votes == '1' && <AlternativeChoice selected={selected} handleSelected={handleSelected} extra={card.card_choice_extra} />}
		</>
	);
};

const StyledChoices = styled.div`
	background: white;
	flex: 1;
	display: flex;
	height: 100%;
	align-items: center;
	flex-direction: column;
	justify-content: space-evenly;
	width: 100%;
	height: 100%;
	padding: 4%;
	gap: 2%;
	.choice {
		background: #00000097;
		color: white;
		height: 2.4rem;
		width: 100%;
		display: flex;
		padding: 2% 8%;
		justify-content: center;
		align-items: center;
		border-radius: 20px;
		transition: all 0.3s ease;
		position: relative;
		text-align: center;
	}
	.selected {
		background: var(--vooter-green);
	}
	.max {
		font-size: 0.8rem;
		position: absolute;
		top: -8%;
		z-index: 2;
	}
	.btnComment {
		position: absolute;
		right: 1.5%;
		height: 91%;
		width: 12%;
		z-index: 40;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	span.comment {
		margin-right: 5%;
		padding: 2%;
		flex: 1;
	}
	span.noComment {
		padding: 2%;
		flex: 1;
	}
`;

export default ChoicesTypeFive;
