import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json';
import fr from './fr.json';

i18next
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		resources: {
			en: {
				translation: en,
			},
			fr: {
				translation: fr,
			},
		},
		// lng: 'en',
		fallbackLng: 'en',
		detection: { order: ['navigator', 'cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'] },
	});

export default i18next;
