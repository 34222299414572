import { Trans } from 'react-i18next';
import styled from 'styled-components';
import img_hand from '../../assets/img_handphone.svg';
import FeaturesApp from '../home/FeaturesApp';

const DownloadAppMessage = () => {
	return (
		<StyledDownloadMessage>
			<div className="image-end-message">
				<img src={img_hand} alt="" />
			</div>
			<div className="top-message">
				<h6>
					<Trans i18nKey="info.feature_am.dl2">
						Pour pouvoir bénéficier de toutes les fonctionnalités, télécharger l'<strong>application Vooter</strong>!
					</Trans>
				</h6>
				<FeaturesApp />
			</div>
		</StyledDownloadMessage>
	);
};

const StyledDownloadMessage = styled.div`
	width: 90%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-around;
	flex: 1;
	.image-end-message {
		height: 30%;
		object-fit: contain;
		img {
			height: 100%;
			width: 100%;
		}
	}
	h6 {
		text-align: center;
		font-size: 15px;
		font-weight: 400;
		margin: -20px 0 20px;
	}
`;

export default DownloadAppMessage;
