import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import vooter from '../assets/small_logo.svg';
import Loader from '../components/UI/Loader';
import { useAxios } from '../hooks/useAxios';

export const RefuseInternalAccess = ({ type }) => {
	const { t } = useTranslation();
	const params = useParams();

	const { response, loading, error } = useAxios({
		url: `/group-internal-user-refuse/${params.token}`,
		method: 'put',
	});

	if (loading)
		return (
			<StyledMessage>
				<Loader />
			</StyledMessage>
		);
	return (
		<StyledMessage>
			<div className="top-message">
				<img src={vooter} alt="" />
				<h3>{t('refuseia.message')}</h3>
				<p>{t('refuseia.submessage')}</p>
			</div>
		</StyledMessage>
	);
};

const StyledMessage = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-around;
	flex: 1;
	img {
		width: 100px;
		margin-bottom: 5%;
	}
	.top-message {
		width: 100%;
		text-align: center;
		h3 {
			font-size: 1.8rem;
			color: var(--dark-grey);
			margin-bottom: 2%;
			font-weight: normal;
		}
		p {
			font-size: 0.9rem;
			color: var(--dark-grey);
		}
	}
`;
