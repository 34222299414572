import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ButtonLinksAppMobil from '../components/home/ButtonLinksAppMobil';
import DownloadAppMessage from '../components/message/DownloadAppMessage';
import End_message from '../components/message/End_message';
import { closeMenuPlus } from '../features/ui/uiSlice';

const Message = ({ type }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(closeMenuPlus());
	}, []);
	return (
		<StyledMessage
			as={motion.div}
			initial={{ opacity: 0, scale: 0 }}
			animate={{ opacity: 1, scale: 1, x: 0 }}
			exit={{ x: 400, opacity: 0 }}
		>
			{type === 'end_diffusion' ? <End_message /> : <DownloadAppMessage />}

			<div className="link">
				<ButtonLinksAppMobil />
			</div>
			<Link className="continu" to="/m/">
				<motion.span
					initial={{ scale: 1 }}
					animate={{ scale: 1.03, transition: { yoyo: Infinity, duration: 0.6 } }}
				>
					{t('global.continu')}
				</motion.span>
			</Link>
		</StyledMessage>
	);
};

const StyledMessage = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	.link {
		height: 21%;
		width: 100%;
	}
	.continu {
		width: 100%;
		background-color: var(--dark-blue);
		height: 11%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
		cursor: pointer;
		transition: background-color 0.3s;
		color: white;
		&:hover {
			background-color: var(--dark-blue2);
		}
	}
`;

export default Message;
