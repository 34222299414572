import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import close from '../../assets/icon_close_cross.svg';
import vooter_logo from '../../assets/vooter.svg';
import { closeSideMenu, openAccount } from '../../features/ui/uiSlice';
import Account from './Account';
import DownloadApp from './DownloadApp';
import FeaturesApp from './FeaturesApp';

const SideMenu = () => {
	const { isSideMenuOpen, isAccountOpen } = useSelector((state) => state.ui);
	const { userGroup } = useSelector((state) => state.group);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const closeSideMenuHandler = () => {
		dispatch(closeSideMenu());
	};

	const handleOpenAccount = () => {
		dispatch(openAccount());
	};

	const quitGroup = () => {
		dispatch(closeSideMenu());
		navigate('/m/groups');
	};

	return (
		<>
			{isAccountOpen && <Account />}
			{!isAccountOpen && isSideMenuOpen && (
				<StyledSidebar
					as={motion.div}
					animate={{ opacity: 1 }}
					initial={{ opacity: 0 }}
					exit={{ opacity: 0 }}
				>
					<div className="sidebar-header">
						<img src={vooter_logo} alt="logo" />
						<button type="button" className="btn-close">
							<img src={close} alt="close" onClick={closeSideMenuHandler} />
						</button>
					</div>
					<div className="sidebar-body">
						<DownloadApp />
						<p>{t('home.sidemenu.discover')}</p>
						<FeaturesApp />
						<div className="section-menu-list">
							<ul className="sidebar-body-links">
								<li onClick={handleOpenAccount}>{t('home.sidemenu.account')}</li>

								<li>
									<a href="mailto:contact@vooter.co">{t('home.sidemenu.contact')}</a>
								</li>
								<li>
									<a href="https://faq-mdv-fr.vooter.co/">FAQ</a>
								</li>
								{userGroup.length > 0 && (
									<li onClick={quitGroup}>{t('home.sidemenu.quit_group')}</li>
								)}
							</ul>
						</div>
					</div>
				</StyledSidebar>
			)}
		</>
	);
};

const StyledSidebar = styled.aside`
	position: fixed;
	top: 0;
	left: 0;
	width: 85%;
	height: 100%;
	padding: 2% 5%;
	background: var(--clr-white);
	z-index: 999;
	overflow-y: scroll;
	max-width: 400px;
	.sidebar-header {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 4%;
	}
	.btn-close {
		background: none;
		border: none;
		position: absolute;
		top: 1rem;
		right: 1rem;
	}
	.sidebar-body > p {
		font-weight: 600;
		font-size: 0.85rem;
		text-align: center;
		margin: 8% 0 3%;
	}

	.section-menu-list {
		margin-top: 10%;
	}
	ul.sidebar-body-links {
		height: 5.1rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		li,
		a {
			color: var(--dark-blue);
			cursor: pointer;
			font-size: 1rem;
			margin-bottom: 0.5rem;
			&:hover {
				color: var(--dark-blue2);
			}
		}
	}
`;

export default SideMenu;
