import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { addAnswer, addComment, addCurrentCard, addSelected, startTimestamp } from '../../../features/diffusion/diffusionSlice';
import { setValue, openModalComment, addChoiceId } from '../../../features/ui/uiSlice';
import AlternativeChoice from './AlternativeChoice';
import Comment_modal from '../Comment_modal';
import iconComment from '../../../assets/icon_comment.svg';
import { useTranslation } from 'react-i18next';

const ChoicesTypeTwo = ({ card }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { selected } = useSelector((state) => state.diffusion);
	const { isCommentModalOpen } = useSelector((state) => state.ui);
	const { consultation_id } = useSelector((state) => state.diffusion);
	const isMoreThanSevenChoices = card.card_choice.length > 7;

	const handleComment = (id) => {
		dispatch(addComment(id));
		dispatch(addChoiceId(id));
		dispatch(openModalComment());
	};

	useEffect(() => {
		dispatch(addCurrentCard(card));
	}, [card]);

	//chaque fois que le select change on change dans answer
	useEffect(() => {
		selected.length > 0 && dispatch(setValue(true));
		selected.length === 0 && dispatch(setValue(false));
		selected.length != 0 && consultation_id != 'gyid' && dispatch(addAnswer());
	}, [selected]);

	// choices<7 selected management
	const handleSelected = (id) => {
		if (selected.find((searchId) => searchId === id)) {
			dispatch(addSelected(selected.filter((it) => it !== id)));
		} else {
			dispatch(addSelected([id]));
		}
	};

	return (
		<>
			<StyledChoices justify={isMoreThanSevenChoices ? 'flex-start' : 'space-evenly'}>
				{!isMoreThanSevenChoices &&
					card.card_choice.map((choice) => (
						<div className={`choice ${selected == choice.id && 'selected'}`} key={choice.id} onClick={() => dispatch(addSelected(choice.id))}>
							<span>{choice.value}</span>
							{choice.is_comment_allowed == '1' && (
								<>
									<div className="btnComment" onClick={() => handleComment(choice.id)}>
										<img src={iconComment} />
									</div>

									{isCommentModalOpen && selected == choice.id && <Comment_modal commentId={choice.id} />}
								</>
							)}
						</div>
					))}
				{isMoreThanSevenChoices && (
					<select name="selected" onChange={(e) => dispatch(addSelected(e.target.value))} defaultValue={'default'} multiple={false}>
						<option value={'default'} disabled>
							{t('broadcast.choice.select_choice')}
						</option>
						{card.card_choice.map((choice, i) => (
							<option value={choice.id} key={choice.value + i}>
								{choice.value}
							</option>
						))}
					</select>
				)}
			</StyledChoices>

			{card.alternative_votes == '1' && <AlternativeChoice selected={selected} handleSelected={handleSelected} extra={card.card_choice_extra} />}
		</>
	);
};

const StyledChoices = styled.div`
	background: white;
	display: flex;
	flex: 1;
	align-items: center;
	flex-direction: column;
	justify-content: ${(props) => props.justify};
	width: 90%;
	padding: 4%;
	gap: 4%;
	.choice {
		background: #686868;
		color: white;
		height: 2.4rem;
		width: 100%;
		display: flex;
		padding: 2% 8%;
		justify-content: center;
		align-items: center;
		border-radius: 20px;
		transition: all 0.3s ease;
		position: relative;
		text-align: center;
	}
	.selected {
		background: var(--vooter-green);
	}
	select {
		background: var(--vooter-blue);
		margin-top: 4%;
		width: 100%;
		height: 2.8rem;
		border: none;
		border-radius: 15px;
		color: white;
		padding: 5px;
	}
	option {
		background-color: white;
		color: var(--general);
	}
	.btnComment {
		position: absolute;
		right: 1.5%;
	}
`;

export default ChoicesTypeTwo;
