import styled from 'styled-components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addGroupByTag, getGroupTag } from '../../features/group/groupSlice';
import { closeMenuPlus } from '../../features/ui/uiSlice';

import { reset } from '../../features/group/groupSlice';
import { useTranslation } from 'react-i18next';

const AddGroupByCode = () => {
	const [inputValue, setInputValue] = useState('');
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const inputHandler = (e) => {
		setInputValue(e.target.value);
	};

	const addGroupByCodeHandler = (e) => {
		e.preventDefault();
		if (inputValue === '') {
			toast.error(t('toast.code_gp'));
			return;
		}
		dispatch(getGroupTag(inputValue));
		dispatch(addGroupByTag());
		setInputValue('');
		dispatch(closeMenuPlus());
		dispatch(reset());
	};
	return (
		<StyledGroupByCode>
			<div className="addGpByCode">
				<div className="addGpByCode-title center-fl">{t('home.menuplus.gp_by_tag')}</div>
				<form className="addGpByCode-body">
					<input type="text" name="code" onChange={inputHandler} />
					<button className="btn btn-addGpByCode" onClick={addGroupByCodeHandler}>
						{t('global.validate')}
					</button>
				</form>
			</div>
		</StyledGroupByCode>
	);
};

const StyledGroupByCode = styled.div`
	.addGpByCode {
		height: 165px;
		&-title {
			background: var(--vooter-orange);
			color: var(--clr-white);
			height: 35px;
			border-radius: 10px 10px 0 0;
		}
		&-body {
			background: var(--clr-white);
			display: flex;
			flex-direction: column;
			padding: 0.5rem 0;
			align-items: center;
			justify-content: space-around;
			height: 130px;
			border-radius: 0 0 10px 10px;
			input {
				background: var(--light-blue5);
				border: none;
				height: 40px;
				width: 80%;
				border-radius: 10px;
				text-align: center;
				font-size: 18px;
			}
			.btn-addGpByCode {
				width: 80%;
				background: var(--vooter-green);
				color: var(--clr-white);
				height: 38px;
			}
		}
	}
`;

export default AddGroupByCode;
