// user info
export const addUserToLocalStorage = (user) => {
	localStorage.setItem('user', JSON.stringify(user));
};

export const removeUserFromLocalStorage = () => {
	localStorage.removeItem('user');
};

export const getUserFromLocalStorage = () => {
	const res = JSON.parse(localStorage.getItem('user'));
	const user = res ? res : null;
	return user;
};

//token_app
export const addTokenAppToLocalStorage = (token) => {
	localStorage.setItem('token_app', token);
};

export const removeTokenAppFromLocalStorage = () => {
	localStorage.removeItem('token_app');
};

export const getTokenAppFromLocalStorage = () => {
	const res2 = localStorage.getItem('token_app');
	const token_app = res2 ? res2 : null;
	return token_app;
};

//current group for diffusion
export const addCurrentGroupToLocalStorage = (group) => {
	localStorage.setItem('current_group', JSON.stringify(group));
	localStorage.setItem('cardIndex', 0);
};

export const getCurrentGroupFromLocalStorage = () => {
	const res = JSON.parse(localStorage.getItem('current_group'));
	const group = res ? res : null;
	return group;
};

export const removeCurrentGroupFromLocalStorage = () => {
	localStorage.removeItem('current_group');
};

//consultation_id for diffusion
export const addConsultationIdToLocalStorage = (consultationId) => {
	localStorage.setItem('consultationId', consultationId);
};

export const getConsultationIdFromLocalStorage = () => {
	const res = localStorage.getItem('consultationId');
	const consultationId = res ? res : null;
	return consultationId;
};

export const removeConsultationIdFromLocalStorage = () => {
	localStorage.removeItem('consultationId');
};

//number total of cards for diffusion
export const addNumberOfCardsToLocalStorage = (numberOfCards) => {
	localStorage.setItem('numberOfCards', numberOfCards);
};

export const getNumberOfCardsFromLocalStorage = () => {
	const res = localStorage.getItem('numberOfCards');
	const numberOfCards = res ? res : null;
	return numberOfCards;
};

export const removeNumberOfCardsFromLocalStorage = () => {
	localStorage.removeItem('numberOfCards');
};

//current index card for diffusion
export const addCardIndexToLocalStorage = (cardIndex) => {
	localStorage.setItem('cardIndex', cardIndex);
};

export const getCardIndexFromLocalStorage = () => {
	const res = localStorage.getItem('cardIndex');
	const cardIndex = res ? res : null;
	return parseInt(cardIndex);
};

export const resetCardIndexFromLocalStorage = () => {
	localStorage.setItem('cardIndex', 0);
};
