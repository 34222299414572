import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import check from '../../assets/icon_check_blue.svg';

const FeaturesApp = () => {
	const { t } = useTranslation();
	return (
		<StyledFeaturesApp>
			<ul className="fonctionnality">
				<li>
					<img src={check} alt="" />
					{t('info.feature_am.res')}
				</li>
				<li>
					<img src={check} alt="" />
					{t('info.feature_am.broadcast')}
				</li>
				<li>
					<img src={check} alt="" />
					{t('info.feature_am.invit')}
				</li>
				<li>
					<img src={check} alt="" />
					{t('info.feature_am.create')}
				</li>
				<li>
					<img src={check} alt="" />
					{t('info.feature_am.access')}
				</li>
			</ul>
		</StyledFeaturesApp>
	);
};

const StyledFeaturesApp = styled.section`
	.fonctionnality {
		font-size: 0.75rem;
		li {
			padding: 0.4rem;
			img {
				padding-right: 0.5rem;
			}
		}
	}
`;

export default FeaturesApp;
