import { configureStore } from '@reduxjs/toolkit';
import uiReducer from '../features/ui/uiSlice';
import authReducer from '../features/auth/authSlice';
import diffusionReducer from '../features/diffusion/diffusionSlice';
import groupReducer from '../features/group/groupSlice';

export const store = configureStore({
	reducer: {
		ui: uiReducer,
		auth: authReducer,
		diffusion: diffusionReducer,
		group: groupReducer,
	},
});
