// utiliser les props avec styled component pour changer les coleurs top dans modale

import { useSelector } from 'react-redux';
import styled from 'styled-components';
import img from '../../../assets/icon_calendar.svg';
import { motion } from 'framer-motion';
import { Trans, useTranslation } from 'react-i18next';

export default function ModalInfo({ diffusion }) {
	const { modalType } = useSelector((state) => state.ui);
	const { t } = useTranslation();
	const getGroupType = (joinType) => {
		if (joinType == 1) {
			return t('home.modal.open_gp');
		} else if (joinType == 3) {
			return t('home.modal.private_gp');
		} else {
			return t('home.modal.open_gp');
		}
	};

	return (
		<StyleModal as={motion.div} animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>
			{modalType === 'group_info' ? (
				<section className="modal">
					<div className="info-group">
						<div className="info-group--top" style={{ background: diffusion.group.join_type_id == '1' ? 'var(--vooter-green) ' : 'var(--vooter-pink) ' }}>
							<div className="info-group--top-secu">{getGroupType(diffusion.group.join_type_id)}</div>
						</div>
						<div className="info-group--body">
							<div className="groupe-name">
								<img src={diffusion.group.logo_media.medium_url} alt="" />
								<h5>{diffusion.group.name}</h5>
							</div>
							<div className="description">{diffusion.group.about}</div>
						</div>
						<div className="info-group--footer center-fl">
							{diffusion.vgroup_acl_id === '6' && (
								<p>
									<Trans i18nKey="home.modal.member_status">
										Vous êtes
										<span> membre </span>de ce groupe
									</Trans>
								</p>
							)}
							{diffusion.vgroup_acl_id === '4' && (
								<p>
									<Trans i18nKey="home.modal.editor_status">
										Vous êtes
										<span> éditeur </span>de ce groupe
									</Trans>
								</p>
							)}
							{(diffusion.vgroup_acl_id === '3' || diffusion.vgroup_acl_id === '1') && (
								<p>
									<Trans i18nKey="home.modal.admin_status">
										Vous êtes
										<span> administrateur </span>de ce groupe
									</Trans>
								</p>
							)}
							{diffusion.vgroup_acl_id === '2' && (
								<p>
									<Trans i18nKey="home.modal.owner_status">
										Vous êtes
										<span> propriétaire </span>de ce groupe
									</Trans>
								</p>
							)}
						</div>
					</div>
				</section>
			) : modalType === 'diffusion_info' ? (
				<section className="modal">
					<div className="info-diff">
						<div className="info-diff--top">
							<div className="info-diff--type">{t('global.broadcast')}</div>
							<div className="info-diff--name">{diffusion.name}</div>
						</div>
						<div className="body-wrap">
							<div className="dates-wrap">
								<img src={img} alt="calendar" />
								<div className="dates">
									<p>
										{t('global.start')} {diffusion.start_date}
									</p>
									<p>
										{t('global.end')}
										{diffusion.end_date}
									</p>
								</div>
							</div>
							<div className="visibility">
								{diffusion.transparency === '1' && <p>{t('home.modal.result_1')} </p>}
								{diffusion.transparency === '2' && <p>{t('home.modal.result_2')}</p>}
								{diffusion.transparency === '3' && <p>{t('home.modal.result_3')}</p>}
							</div>
							<div className="anonymity">
								{diffusion.anonymity === '1' && (
									<p>
										<Trans i18nKey="home.modal.result_anonymous">
											Vos réponses sont <span>anonymisées.</span>
										</Trans>
									</p>
								)}
								{diffusion.anonymity === '0' && (
									<p>
										<Trans i18nKey="home.modal.result_not_anonymous">
											Les avis ou votes seront <span>visibles</span> par les administrateurs du groupe.
										</Trans>
									</p>
								)}
							</div>
							<div className="security">
								{diffusion.security_level === '0' && (
									<p>
										<Trans i18nKey="home.modal.open_broadcast">
											Cette consultation est <span>ouverte à tous.</span>
										</Trans>
									</p>
								)}
								{diffusion.security_level === '1' && (
									<p>
										{t('global.security_lvl_1')} {diffusion.start_date}
									</p>
								)}
								{diffusion.security_level === '2' && <p>{t('global.security_lvl_2')} </p>}
							</div>
						</div>
					</div>
				</section>
			) : (
				''
			)}
		</StyleModal>
	);
}

const StyleModal = styled.div`
	width: 90%;
	z-index: 55;
	position: fixed;
	top: 10%;
	border-radius: 1.5rem;
	.info-group {
		display: grid;
		grid-template-rows: 37px auto 38px;
		&--top {
			width: 100%;
			height: 2.3rem;
			color: white;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 1.5rem 1.5rem 0 0;
		}
		&--body {
			padding: 1rem;
			background: white;
		}
		&--footer {
			background: var(--light-grey);
			border-radius: 0 0 1.5rem 1.5rem;
			span {
				text-transform: uppercase;
			}
		}
	}
	.description {
		text-align: center;
		margin: 0.2rem auto;
	}
	.info-group--top-secu {
		text-transform: uppercase;
	}

	.groupe-name {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		img {
			height: 3.5rem;
			width: 3.5rem;
			margin-right: 1rem;
			border-radius: 5px;
			border: 1px solid lightgrey;
		}
	}

	.info-diff {
		width: 100%;
		&--top {
			height: 4.9rem;
			color: white;
			font-size: 1.1rem;
		}
		&--type {
			background: var(--dark-blue);
			border-radius: 15px 15px 0 0;
			height: 1.88rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&--name {
			background: var(--light-blue1);
			text-align: center;
			height: 3rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.body-wrap {
		background: white;
		min-height: 16.25rem;
		border-radius: 0 0 15px 15px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		text-align: center;
		padding: 1.5rem;
		span {
			font-weight: 700;
		}
	}
	.dates-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 1.1rem;
		color: var(--light-blue1);
		p {
			font-size: 0.8rem;
			text-align: left;
		}
	}
	p {
		font-size: 1rem;
	}
`;
