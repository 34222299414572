import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { hideOverlay, closeSideMenu } from '../../features/ui/uiSlice';
import { resetIsSuccess } from '../../features/group/groupSlice';
import { motion } from 'framer-motion';

const Overlay = () => {
	const dispatch = useDispatch();
	const hideOverlayHandler = () => {
		dispatch(hideOverlay());
		dispatch(closeSideMenu());
		dispatch(resetIsSuccess());
	};
	return <StyledOverlay onClick={hideOverlayHandler} as={motion.div} animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}></StyledOverlay>;
};

const StyledOverlay = styled.section`
	position: fixed;
	z-index: 50;
	background-color: var(--bg-modal);
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100%;
`;

export default Overlay;
