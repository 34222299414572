import styled from 'styled-components';
import info from '../../assets/icon_info_white.svg';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { openModalDetail } from '../../features/ui/uiSlice';
import { useTranslation } from 'react-i18next';

const Question = ({ question, about }) => {
	const { t } = useTranslation();
	const showModalDetail = useSelector((state) => state.ui.isOpenModalDetail);
	const dispatch = useDispatch();
	const handleModal = () => {
		dispatch(openModalDetail());
	};
	return (
		<>
			<StyledQuestion as={motion.div} animate={{ opacity: 1, scale: 1 }} initial={{ opacity: 0, scale: 0.5 }} transition={{ type: 'spring', duration: 0.7, delay: 0.8 }} exit={{ opacity: 0 }}>
				<p> {question}</p>
			</StyledQuestion>
			{about != null && (
				<StyledAbout as={motion.div} className="about-wrap" whileTap={{ scale: 0.8 }}>
					<span className="about-content" onClick={handleModal}>
						<img src={info} alt="" />
						{t('broadcast.choice.more_details')}
					</span>

					{showModalDetail && (
						<motion.div className="modal-details" animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>
							<div className="modal-details-top center-fl">
								<span>{t('broadcast.choice.more_info')}</span>
							</div>
							<div className="modal-details-content">
								<span>{about}</span>
							</div>
						</motion.div>
					)}
				</StyledAbout>
			)}
		</>
	);
};

const StyledQuestion = styled.div`
	background: #00000075;
	color: white;
	border-radius: 20px;
	padding: 20px;
	position: relative;
	font-size: 14px;
	width: 80%;

	p {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 0.9rem;
	}
`;

const StyledAbout = styled.div`
	min-width: 85%;
	color: white;
	margin-top: -11px;
	z-index: 50px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: -5%;
	.about-content {
		background-color: var(--vooter-orange);
		display: flex;
		justify-content: center;
		gap: 3%;
		align-items: center;
		height: 36px;
		width: 9rem;
		border-radius: 20px;
		transition: all 0.3s ease-in-out;
		&:hover {
			background-color: var(--vooter-orange-dark);
		}
	}
	.modal-details {
		background: white;
		position: absolute;
		color: grey;
		border-radius: 15px;
		z-index: 999;
		width: 100%;
		margin-top: -122%;
		&-top {
			background-color: var(--vooter-orange);
			font-size: 0.9rem;
			color: white;
			height: 43px;
			border-radius: 15px 15px 0 0;
		}
		&-content {
			font-size: 14px;
			padding: 25px;
			text-align: center;
			line-height: 20px;
		}
	}
`;

export default Question;
