import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ButtonLinksAppMobil from './ButtonLinksAppMobil';

const DownloadApp = () => {
	return (
		<StyledDownloadApp className="section-center">
			<div className="section-top">
				<p>
					<Trans i18nKey="info.feature_am.dl">
						Télécharger l’application <span>Vooter</span> disponible sur :
					</Trans>
				</p>
			</div>
			<ButtonLinksAppMobil />
		</StyledDownloadApp>
	);
};

const StyledDownloadApp = styled.section`
	background: var(--clr-white);
	width: 90%;
	.section-top {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		p {
			font-size: 1rem;
			margin-bottom: 1rem;
			span {
				color: var(--vooter-blue);
			}
		}
	}
	.section-links {
		display: flex;
		flex-direction: column;
		height: 130px;
		justify-content: space-around;
		align-items: center;
		margin: 1rem auto;
		a {
			position: relative;
			background: var(--light-blue3);
			width: 100%;
			color: var(--general);
			img {
				position: absolute;
				left: 1rem;
			}
		}
	}
`;

export default DownloadApp;
