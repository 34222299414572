import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { addAnswer, addCurrentCard } from '../../../features/diffusion/diffusionSlice';
import { setValue, openModalNoComment, showNextCard, closeModalNoComment } from '../../../features/ui/uiSlice';
import { useTranslation } from 'react-i18next';

//type open
const ChoicesTypeFour = ({ card }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isOpenModal = useSelector((state) => state.ui.isOpenModalNoComment);

	const [selected, setSelected] = useState();
	const [comment, setComment] = useState('');

	useEffect(() => {
		dispatch(addCurrentCard(card));
	}, [card]);

	useEffect(() => {
		dispatch(addAnswer({ comment, selected }));
	}, [comment]);

	useEffect(() => {
		setSelected(card.card_choice[0].id);
	}, []);

	const handleChange = (e) => {
		setComment(e.target.value);
		e.target.value.length > 0 && dispatch(setValue(true));
		e.target.value.length === 0 && dispatch(setValue(false));
	};

	const handleNoComment = () => {
		setComment('');
		dispatch(openModalNoComment());
	};

	const showNext = () => {
		dispatch(showNextCard());
		dispatch(closeModalNoComment());
	};
	return (
		<>
			<StyledChoices>
				<div className="box">
					<textarea name="openQuestion" id="openQuestion" placeholder={t('broadcast.choice.write_comment')} autoFocus value={comment} onChange={handleChange}></textarea>
					<button className="btn" onClick={handleNoComment}>
						{t('broadcast.choice.no_comment')}
					</button>
				</div>

				{isOpenModal && (
					<motion.div className="noComment" animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>
						<div className="noComment-body center-fl">
							<span> {t('broadcast.choice.no_comment_confirm')}</span>
						</div>
						<div className="noComment-btn center-fl" onClick={showNext}>
							{t('global.confirm')}
						</div>
					</motion.div>
				)}
			</StyledChoices>
		</>
	);
};

const StyledChoices = styled.div`
	flex: 1;
	width: 100%;
	background: white;
	.box {
		display: flex;
		flex-direction: column;
		height: 100%;
		align-items: center;
		justify-content: space-around;
	}
	textarea {
		resize: none;
		border-radius: 15px;
		border: none;
		box-shadow: var(--light-shadow);
		padding: 20px;
		width: 90%;
		height: 50%;
		min-height: 6.5rem;
		&:focus {
			box-shadow: var(--dark-shadow);
		}
	}
	.btn {
		width: 90%;
		background: var(--dark-grey);
		color: white;
		transition: 0.3s all;
		&:hover {
			filter: brightness(0.9);
		}
	}
	.noComment {
		background-color: white;
		height: 190px;
		position: absolute;
		z-index: 999;
		width: 90%;
		text-align: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 15px;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		max-width: 460px;

		&-btn {
			background-color: var(--vooter-orange);
			color: white;
			height: 66px;
			border-radius: 0 0 15px 15px;
			transition: all 0.3s;
			&:hover {
				background-color: var(--vooter-orange-dark);
			}
		}
		&-body {
			height: 100%;
			span {
				padding: 30px;
				line-height: 30px;
			}
		}
	}
`;

export default ChoicesTypeFour;
